import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { BACKEND_WS_URL } from "../../config";

export const wsLink = new GraphQLWsLink(
	createClient({
		url: BACKEND_WS_URL,
		keepAlive: 10_000,
		lazy: true,
		retryAttempts: 10,
	}),
);
