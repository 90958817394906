import { split } from "@apollo/client";

import { getMainDefinition } from "@apollo/client/utilities";

import { automaticallyPersistedQueryLink } from "./automaticallyPersistedQuery.link";
import { wsLink } from "./ws.link";
import { httpLink } from "./http.link";
import { authLink } from "./auth.link";

export const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);

		return definition.kind === "OperationDefinition" && definition.operation === "subscription";
	},
	wsLink,
	authLink.concat(automaticallyPersistedQueryLink.concat(httpLink)),
);
