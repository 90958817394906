import { ApolloLink } from "@apollo/client";

import { omitDeep } from "../../utils/apollo";

export const cleanTypenameLink = new ApolloLink((operation, forward) => {
	if (operation.variables && !operation.variables.file) {
		operation.variables = omitDeep(operation.variables, "__typename");
	}

	return forward(operation);
});
