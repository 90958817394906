import { BrowserRouter, Route, Routes } from "react-router-dom";

import Fallback404 from "@/pages/Fallback404";

import { ReactLazyPreload } from "../preload";
import ModeContextProvider from "../ModeContext.provider";
import DefaultLayout from "@/components/environments/DefaultLayout";
import { LazyRoute } from "@/LazyRoute";

const HomePage = ReactLazyPreload(() => import("./page"));
const SearchRoutes = ReactLazyPreload(() => import("./search/routes"));
const QRCodeRoutes = ReactLazyPreload(() => import("./qr-code/routes"));
const VenueRoutes = ReactLazyPreload(() => import("./venue/routes"));
const VRoutes = ReactLazyPreload(() => import("./v/routes"));

const Root = () => {
	return (
		<BrowserRouter>
			<ModeContextProvider>
				<Routes>
					<Route element={<LazyRoute />}>
						<Route path="/" element={<HomePage />} />

						<Route path="/*" element={<SearchRoutes />} />

						<Route path="/qrCode/*" element={<QRCodeRoutes />} />

						<Route path="/venue/*" element={<VenueRoutes />} />

						<Route path="/v/*" element={<VRoutes />} />

						<Route
							path="*"
							element={
								<DefaultLayout>
									<Fallback404 text="Die Seite konnte nicht gefunden werden" />
								</DefaultLayout>
							}
						/>
					</Route>
				</Routes>
			</ModeContextProvider>
		</BrowserRouter>
	);
};

export default Root;
