export const home = {
	"Hello!": "Hallo!",
	Retry: "Erneut versuchen",
	"Scan qr code": "QR Code scannen",
	"Search nearby": "In der Nähe suchen",
	"Waiting to allow location permissions": "Warte auf Standortfreigabe",
	"User denied Geolocation": "Bitte erlaube die Standortfreigabe in deinem Browser.",
	"Welcome to the saferspaces app. Scan a qr code nearby to notify the security staff with your current position":
		"Willkommen zur saferspaces WebApp. Scanne jetzt einen QR Code in der Nähe, um das Team über deinen aktuellen Standort zu informieren.",
	"Which place is nearby?": "Welcher Ort befindet sich in deiner Nähe?",
} as const;
