import { ApolloClient, ApolloLink } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { __DEV__ } from "@apollo/client/utilities/globals";

import { NAME, VERSION } from "../config";

import { cache } from "./cache";

import { errorLink } from "./links/error.link";
import { sentryLink } from "./links/sentry.link";
import { splitLink } from "./links/split.link";
import { cleanTypenameLink } from "./links/cleanTypename.link";

const client = new ApolloClient({
	name: NAME,
	version: VERSION,
	link: ApolloLink.from([cleanTypenameLink, sentryLink, errorLink, splitLink]),
	cache,
});

if (__DEV__) {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

export default client;
