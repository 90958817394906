import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never;
};
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	DateTimeISO: { input: string; output: string };
};

export type Anonymous = {
	__typename?: "Anonymous";
	uuid: Scalars["String"]["output"];
};

export type AppRefreshResult = AppRefreshReturn | ErrorReturn;

export type AppRefreshReturn = {
	__typename?: "AppRefreshReturn";
	accessToken: Scalars["String"]["output"];
	sessionId: Scalars["String"]["output"];
};

export type AssignNfcTagToQrCodeResult = ErrorReturn | NfcTag;

export type AuthUser = {
	__typename?: "AuthUser";
	email: Scalars["String"]["output"];
	id: Scalars["String"]["output"];
};

/** Generic interface to be implemented by specific error returns */
export type BackendError = {
	i18nKey: Scalars["String"]["output"];
	message: Scalars["String"]["output"];
	statusCode: StatusCodes;
};

export type Booking = {
	__typename?: "Booking";
	cancelledAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	endsAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	externalId?: Maybe<Scalars["String"]["output"]>;
	featureType: BookingFeatureType;
	id: Scalars["String"]["output"];
	provider?: Maybe<BookingProvider>;
	startsAt: Scalars["DateTimeISO"]["output"];
	status?: Maybe<Scalars["String"]["output"]>;
	subscriptionAmount: Scalars["Float"]["output"];
	timestamp: Timestamp;
	trialEndsAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	type: BookingType;
	user: User;
	venue: Venue;
};

export enum BookingFeatureType {
	Basic = "basic",
	Free = "free",
	Premium = "premium",
}

export type BookingFilterInput = {
	active?: InputMaybe<Scalars["Boolean"]["input"]>;
	inactive?: InputMaybe<Scalars["Boolean"]["input"]>;
	upcoming?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum BookingProvider {
	Manual = "manual",
	Paypal = "paypal",
	Stripe = "stripe",
}

export enum BookingType {
	Free = "free",
	Payment = "payment",
	Subscription = "subscription",
}

export type CancelBookingResult = Booking | ErrorReturn;

export type Chat = {
	__typename?: "Chat";
	id: Scalars["String"]["output"];
	messages: Array<ChatMessage>;
};

export type ChatMessage = {
	__typename?: "ChatMessage";
	date: Scalars["DateTimeISO"]["output"];
	id: Scalars["String"]["output"];
	sender?: Maybe<Scalars["String"]["output"]>;
	text: Scalars["String"]["output"];
	type: MessageType;
};

export type Circle = {
	__typename?: "Circle";
	center: RequiredLatLng;
	id: Scalars["String"]["output"];
	radius: Scalars["Float"]["output"];
};

export type CircleInput = {
	center: LatLngRequiredInput;
	id: Scalars["String"]["input"];
	radius: Scalars["Float"]["input"];
};

export type ColorType = {
	cyan: Scalars["Float"]["input"];
	key: Scalars["Float"]["input"];
	magenta: Scalars["Float"]["input"];
	yellow: Scalars["Float"]["input"];
};

export type CoordinatesInput = {
	altitude?: InputMaybe<Scalars["Float"]["input"]>;
	lat?: InputMaybe<Scalars["Float"]["input"]>;
	lng?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CoordinatesNonNull = {
	__typename?: "CoordinatesNonNull";
	address: Scalars["String"]["output"];
	altitude: Scalars["Float"]["output"];
	lat: Scalars["Float"]["output"];
	lng: Scalars["Float"]["output"];
	radius: Scalars["Float"]["output"];
};

export type CoordinatesRequiredInput = {
	altitude: Scalars["Float"]["input"];
	lat: Scalars["Float"]["input"];
	lng: Scalars["Float"]["input"];
};

export type CreateBookingResult = CreateBookingSuccess | ErrorReturn;

export type CreateBookingSuccess = {
	__typename?: "CreateBookingSuccess";
	url?: Maybe<Scalars["String"]["output"]>;
};

export type CreateLocationResult = ErrorReturn | Location;

export type CreateManualPaymentResult = ErrorReturn | GraphQlBooking;

export type CreateManualSubscriptionResult = ErrorReturn | GraphQlBooking;

export type CreateMultipleLocationsResult = ErrorReturn | LocationList;

export type CreatePermissionInput = {
	email: Scalars["String"]["input"];
	name: Scalars["String"]["input"];
	role: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
	venueName: Scalars["String"]["input"];
};

export type CreatePermissionResult = ErrorReturn | Permission;

export type CreatePositionBatchedInput = {
	count: Scalars["Int"]["input"];
	countInternal: Scalars["Int"]["input"];
	name: Scalars["String"]["input"];
};

export type CreatePrintJobInput = {
	color: PrintJobColorType;
	count: Scalars["Int"]["input"];
	format: Format;
	settings: PrintJobSettingsType;
	template: Template;
	venueId: Scalars["String"]["input"];
};

export type CreateVenueResult = CreateVenueSuccessResult | ErrorReturn;

export type CreateVenueSuccessResult = {
	__typename?: "CreateVenueSuccessResult";
	accessToken: Scalars["String"]["output"];
	venue: Venue;
};

export type CurrentPosition = {
	__typename?: "CurrentPosition";
	createdAt: Scalars["DateTimeISO"]["output"];
	lat: Scalars["Float"]["output"];
	lng: Scalars["Float"]["output"];
	updatedAt: Scalars["DateTimeISO"]["output"];
};

export type CurrentSecurityPositionsReturn = CurrentSecurityPositionsSuccessResult | ErrorReturn;

export type CurrentSecurityPositionsSuccessResult = {
	__typename?: "CurrentSecurityPositionsSuccessResult";
	positions: Array<RequiredLatLng>;
};

export type DeleteFloorResult = ErrorReturn | SuccessReturn;

export type DeletePositionResult = ErrorReturn | SuccessReturn;

export type EditLocationResult = ErrorReturn | Location;

export type EditPermissionResult = ErrorReturn | Permission;

export type EditPositionResult = ErrorReturn | Position;

export type EditVenueDetailsResult = ErrorReturn | VenueDetails;

/** Generic error return, currently used in many placed. Replace by more specific errors */
export type ErrorReturn = BackendError & {
	__typename?: "ErrorReturn";
	i18nKey: Scalars["String"]["output"];
	message: Scalars["String"]["output"];
	statusCode: StatusCodes;
};

export enum Format {
	A2 = "A2",
	A3 = "A3",
	A4 = "A4",
	A6 = "A6",
}

export type GeofenceAreaInput = {
	circles: Array<CircleInput>;
	polygons: Array<PolygonInput>;
};

export type Geofencing = {
	__typename?: "Geofencing";
	circles: Array<Circle>;
	polygons: Array<Polygon>;
	requirePosition: Scalars["Boolean"]["output"];
	requirePositionForQRCodes: Scalars["Boolean"]["output"];
	requirePositionForVenue: Scalars["Boolean"]["output"];
};

export type GraphQlBooking = {
	__typename?: "GraphQLBooking";
	cancelledAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	endsAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	id: Scalars["String"]["output"];
	startsAt: Scalars["DateTimeISO"]["output"];
	status?: Maybe<Scalars["String"]["output"]>;
	timestamp: Timestamp;
	trialEndsAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	type: BookingType;
	user: User;
	venue: Venue;
};

export type HelpRequest = {
	__typename?: "HelpRequest";
	date?: Maybe<Scalars["DateTimeISO"]["output"]>;
	id: Scalars["String"]["output"];
	language: Scalars["String"]["output"];
	location?: Maybe<Scalars["String"]["output"]>;
	person: Person;
	position?: Maybe<Scalars["String"]["output"]>;
	situation: Situation;
	text: Scalars["String"]["output"];
	title: Scalars["String"]["output"];
};

export type HelpStream = {
	__typename?: "HelpStream";
	entries: Array<HelpStreamEntry>;
	venueId: Scalars["String"]["output"];
};

export type HelpStreamEntry = {
	__typename?: "HelpStreamEntry";
	date: Scalars["DateTimeISO"]["output"];
	id: Scalars["String"]["output"];
	isOutdated: Scalars["Boolean"]["output"];
	language: Scalars["String"]["output"];
	lastMessageFrom?: Maybe<Scalars["DateTimeISO"]["output"]>;
	location?: Maybe<Scalars["String"]["output"]>;
	person?: Maybe<Person>;
	position?: Maybe<Scalars["String"]["output"]>;
	situation?: Maybe<Situation>;
	text: Scalars["String"]["output"];
	title: Scalars["String"]["output"];
	type: Scalars["String"]["output"];
	uuid: Scalars["String"]["output"];
	venue: Scalars["String"]["output"];
	venueId: Scalars["String"]["output"];
};

export type HelpStreamReturn = ErrorReturn | PaginatedHelpStreamResponse;

export enum Items {
	OneTimeFixed = "ONE_TIME_FIXED",
	SubscriptionFixed = "SUBSCRIPTION_FIXED",
}

export enum InputValueType {
	Boolean = "boolean",
	BooleanArray = "booleanArray",
	Float = "float",
	FloatArray = "floatArray",
	Int = "int",
	IntArray = "intArray",
	Number = "number",
	NumberArray = "numberArray",
	String = "string",
	StringArray = "stringArray",
}

export type LatLngRequiredInput = {
	lat: Scalars["Float"]["input"];
	lng: Scalars["Float"]["input"];
};

export type Location = {
	__typename?: "Location";
	coordinates?: Maybe<CoordinatesNonNull>;
	id: Scalars["String"]["output"];
	name: Scalars["String"]["output"];
	positions: Array<Position>;
	timestamp: Timestamp;
	venue: Venue;
};

export type LocationList = {
	__typename?: "LocationList";
	locations: Array<Location>;
};

export type MagicLinkForAppResult = ErrorReturn | MagicLinkReturn;

export type MagicLinkReturn = {
	__typename?: "MagicLinkReturn";
	uuid: Scalars["String"]["output"];
};

export type MarginType = {
	bleed: Scalars["Float"]["input"];
	margin: Scalars["Float"]["input"];
};

export type MeResult = Anonymous | ErrorReturn | User;

export enum MessageType {
	PositionEnded = "positionEnded",
	PositionShared = "positionShared",
	Text = "text",
}

export type Mutation = {
	__typename?: "Mutation";
	/**
	 *
	 *     # Activate QR Codes for a certain amount of hours
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 */
	activateHours: Venue;
	/**
	 *
	 *     # Refresh access token
	 *
	 *     Since access tokens are short-lived, they need to be refreshed regularly. This can be done by
	 *     providing a valid session id which in stored in Redis. A session is valid for 7 days and will
	 *     be refresh also, meaning valid 7 days after the last refresh.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	appRefresh: AppRefreshResult;
	/**
	 *
	 *     # Assigns a NFC Tag to a QR Code.
	 *
	 *     This mutation allows authorized users to assign a NFC Tag to a QR Code.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 *     ## Permissions Required
	 *     To assign a NFC Tag to a QR Code, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, this functionality is not available yet.
	 *
	 */
	assignNfcTagToQrCode: AssignNfcTagToQrCodeResult;
	assignQRCode: Scalars["Boolean"]["output"];
	/**
	 *
	 *     # Cancel a booking
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	cancelBooking?: Maybe<CancelBookingResult>;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	createAutomaticResponse: SuccessOrErrorReturnResult;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	createHelpRequestResponse: Scalars["Boolean"]["output"];
	/**
	 *
	 *     # Create a new area/location for a venue
	 *
	 *     This mutation allows authorized users to create a new location (or area) within a specific venue.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 *     ## Permissions Required
	 *     To create a location, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being created**:
	 *
	 *     - **permission:venueId,role=Admin** → Can fully manage the venue.
	 *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
	 *     - **permission:venueId,role=Security** → May need to create locations when assigning QR Codes.
	 *     - **permission:venueId,role=SecurityTeam** → May need to create locations when assigning QR Codes.
	 *     - **permission:venueId,role=Awareness** → May need to create locations when assigning QR Codes.
	 *     - **permission:venueId,role=AwarenessTeam** → May need to create locations when assigning QR Codes.
	 *
	 */
	createLocation: CreateLocationResult;
	/**
	 *
	 *     # Create a manual payment for a booking
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createManualPayment: CreateManualPaymentResult;
	/**
	 *
	 *     # Create a manual subscription for a booking
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createManualSubscription: CreateManualSubscriptionResult;
	/**
	 *
	 *     # Create multiple new areas/locations for a venue
	 *
	 *     This mutation allows authorized users to create new locations (or areas) within a specific venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 *     ## Permissions Required
	 *     To create locations, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being created**:
	 *
	 *     - **permission:venueId,role=Admin** → Can fully manage the venue.
	 *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
	 *
	 */
	createMultipleLocations: CreateMultipleLocationsResult;
	/**
	 *
	 *     # Create a payment for a venue
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createPayment: CreateBookingResult;
	createPermission: CreatePermissionResult;
	createPositionBatched: Array<Location>;
	createPrintJob: PrintJob;
	/**
	 *
	 *     # Create a dynamic QR code
	 *
	 *     ## Used by
	 *
	 *     - Admin (web)
	 *
	 */
	createQRCodeDynamic: QrCodeDynamic;
	createQRCodeHelp: QrCodeHelp;
	createQRCodeInternalChat: QrCodeInternalChat;
	/**
	 *
	 *     # Create a subscription for a venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createSubscription: CreateBookingResult;
	/**
	 *
	 *     # Creates a test venue by name only and adds 2 test QR Codes
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createTestVenue: Venue;
	/**
	 *
	 *     # Create a trial for a venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	createTrial: CreateBookingResult;
	createVenue: CreateVenueResult;
	/**
	 *
	 *     # Delete a location
	 *
	 *     This mutation allows authorized users to delete a location.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 *     ## Permissions Required
	 *     To delete a location, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being deleted**:
	 *
	 *     - **permission:venueId,role=Admin** → Can fully manage the venue.
	 *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
	 *
	 */
	deleteLocation: DeleteFloorResult;
	deletePermission: Scalars["String"]["output"];
	deletePosition: DeletePositionResult;
	deleteUser: AuthUser;
	deleteUserCodeConfirmation: Scalars["Boolean"]["output"];
	deleteUserTokenConfirmation: Scalars["Boolean"]["output"];
	/**
	 *
	 *     # Edit a location's name
	 *
	 *     This mutation allows authorized users to edit a location's name.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 *     ## Permissions Required
	 *     To edit a location's name, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, they must have **one** of the following roles **for the venue where the location is being edited**:
	 *
	 *     - **permission:venueId,role=Admin** → Can fully manage the venue.
	 *     - **permission:venueId,role=Owner** → Venue owner, has full permissions.
	 *     - **permission:venueId,role=Security** → May need to edit a location's name when assigning QR Codes.
	 *     - **permission:venueId,role=SecurityTeam** → May need to edit a location's name when assigning QR Codes.
	 *     - **permission:venueId,role=Awareness** → May need to edit a location's name when assigning QR Codes.
	 *     - **permission:venueId,role=AwarenessTeam** → May need to edit a location's name when assigning QR Codes.
	 *
	 */
	editLocationName: EditLocationResult;
	editPermission: EditPermissionResult;
	editPosition: EditPositionResult;
	editPositionCoordinates: EditPositionResult;
	editPositionLocation: EditPositionResult;
	editVenueActive: Venue;
	editVenueDetails: EditVenueDetailsResult;
	editVenueImage: Venue;
	editVenueName: Venue;
	editVenuePhoneNumber: Venue;
	/**
	 *
	 *     # Update inactivity timeout
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	editVenueSettingsInactivityTimeout: Venue;
	/**
	 *
	 *     # Get MagicLink [DEPRECATED]
	 *
	 *     ⚠️ This mutation is deprecated and should be removed after 2025-08-01.
	 *
	 *     Legacy description:
	 *     Creates a 5-digit code that is stored in redis with a generated uuid for 15 minutes and sends
	 *     the code to the given email address. If successful, it returns the uuid for the subsequent
	 *     verification request. If no user is found with the given email address, it returns an error
	 *     instead.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 * @deprecated Use login with email or oauth instead
	 */
	getMagicLinkForApp: MagicLinkForAppResult;
	/** @deprecated Switch to Query to allow for refetch */
	healthCheck: Scalars["String"]["output"];
	/**
	 *
	 *     # Logout
	 *
	 *     Logging out will clear the fcm token to not send push notifications to useres anymore as well as
	 *     clearing their current session.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *     - Admin (Web)
	 *
	 */
	logoutMobile: SuccessReturn;
	/**
	 *
	 *     # Reassigns a NFC Tag to a QR Code.
	 *
	 *     This mutation allows authorized users to reassign a NFC Tag to a QR Code.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 *     ## Permissions Required
	 *     To reassign a NFC Tag to a QR Code, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, this functionality is not available yet.
	 *
	 */
	reassignNfcTagToQrCode: ReassignNfcTagToQrCodeResult;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	resetCurrentPosition: Scalars["Boolean"]["output"];
	resetVenueArchived: Venue;
	retryPrintJob: PrintJob;
	sendMail: SuccessReturn;
	/**
	 *
	 *     # Sends a link to the given email address to reset the password
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	sendPasswordResetLink: SendPasswordResetLinkReturnType;
	setVenueArchived: Venue;
	setVenueDeleted: Venue;
	setVenueGeofence: SetVenueGeofenceResult;
	setVenueGeofenceSettings: SetVenueGeofenceResult;
	setVenueSituationsSettings: SetVenueSituationsSettingsResult;
	/**
	 *
	 *     # Verifies an idToken from firebase WITHOUT creating a new user
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	signInWithIdToken: SignInWithIdTokenReturnType;
	/**
	 *
	 *     # Register
	 *
	 *     Registers a new user with the given email address. If a user with the email address already
	 *     exists, it return an error response instead. Note, all users are currently registered as owners
	 *     by default. Security staff has to be invited after the creation of a venue. Any user,
	 *     irrespective if he or shes has been registered globally as an owner, can be given permissions
	 *     as 'security' or 'owner' for a given venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 *     ## Side effects
	 *
	 *     - Sends out a notification to 'saferspaces' via Slack with the registered email address.
	 *
	 */
	signup: RegisterResult;
	/**
	 *
	 *     # Register
	 *
	 *     Registers a new user with the given email address from the idToken. If a user with the email
	 *     address already exists, it return an error response instead. Note, all users are currently
	 *     registered as owners by default. Security staff has to be invited after the creation of a venue.
	 *     Any user, irrespective if he or shes has been registered globally as an owner, can be given
	 *     permissions as 'security' or 'owner' for a given venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 *     ## Side effects
	 *
	 *     - Sends out a notification to 'saferspaces' via Slack with the registered email address.
	 *
	 */
	signupWithIdToken: RegisterResult;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	submitDynamicRequest: Scalars["String"]["output"];
	/**
	 *
	 *     # Scan of a visitor code
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	submitHelpRequest: Scalars["String"]["output"];
	/**
	 *
	 *     # Scan of a staff code
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	submitInternalHelpRequest: Scalars["String"]["output"];
	/**
	 *
	 *     # Scan of a venue code
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	submitVenueHelpRequest: Scalars["String"]["output"];
	/**
	 *
	 *     # Toggle active status of QR Codes
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *     - Admin (Web)
	 *
	 */
	toggleIsActive: Venue;
	toggleNotifications: Notification;
	/**
	 *
	 *     # Activate QR Codes for a certain amount of hours
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	toggleVenueActiveFeedback: Venue;
	/**
	 *
	 *     # Unassigns a NFC Tag from a QR Code.
	 *
	 *     This mutation allows authorized users to unassign a NFC Tag from a QR Code.
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 *     ## Permissions Required
	 *     To unassign a NFC Tag from a QR Code, the user must meet **one** of the following conditions:
	 *
	 *     ### ** Global Permission**
	 *     - **user:role=Admin** → Grants global admin access (can manage all venues).
	 *
	 *     ### ** Venue-Specific Permissions**
	 *     For users without global admin rights, this functionality is not available yet.
	 *
	 */
	unassignNfcTagFromQrCode: UnassignNfcTagFromQrCodeResult;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	updateCurrentPosition: Scalars["String"]["output"];
	updateFCMToken: UpdateUserResult;
	updateLanguage: Scalars["String"]["output"];
	updateLastCharacterTypedAt: Scalars["Boolean"]["output"];
	updateLocationCoordinates: Location;
	updateName: UpdateUserResult;
	updateNotificationChannel: NotificationChannel;
	updatePhoneNumber: UpdateUserResult;
	updateSteps: QrCodeDynamic;
	/**
	 *
	 *     # Activate QR Codes for a certain amount of hours
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	updateVenueActiveFeedback: Venue;
	updateVenueCoordinates: Venue;
	updateVenueTheme?: Maybe<VenueTheme>;
	updateVenueThemeText?: Maybe<VenueTheme>;
	updateVenueThemeTextBulk?: Maybe<VenueTheme>;
	upsertNotificationChannel: NotificationChannel;
	/**
	 *
	 *     # Verifies an idToken from firebase WITH creating a new user if not exists
	 *
	 *     ## Used by
	 *
	 *     - Security (Mobile)
	 *
	 */
	verifyIdToken: VerifyIdTokenReturnType;
	/**
	 *
	 *     # Verify MagicLink [DEPRECATED]
	 *
	 *     ⚠️ This mutation is deprecated and should be removed after 2025-08-01.
	 *
	 *     Legacy description:
	 *     Verifies the code stored in redis given uuid. If successfull, returns an accessToken (short
	 *     lived for 15 minutes) and sessionId (valid for 7 days, stored in redis). Important: To not
	 *     create multiple sessions for the same user, the session id currently equals the user id. This is
	 *     highly unsatisfactory and should be adjusted as soon as possible.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *     - Security (Mobile)
	 *
	 *     ## Side effects
	 *
	 *     - Publishes an update for the onboarding step after a successful login
	 *
	 * @deprecated Use login with email or oauth instead
	 */
	verifyMagicLink: VerifyMagicLinkResult;
	writeMessage: ChatMessage;
	writeVenueFeedback: Scalars["Boolean"]["output"];
};

export type MutationActivateHoursArgs = {
	hoursToActivate: Scalars["Int"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationAppRefreshArgs = {
	sessionId: Scalars["String"]["input"];
};

export type MutationAssignNfcTagToQrCodeArgs = {
	qrCodeId: Scalars["String"]["input"];
};

export type MutationAssignQrCodeArgs = {
	coordinates?: InputMaybe<CoordinatesRequiredInput>;
	locationId: Scalars["String"]["input"];
	positionName: Scalars["String"]["input"];
	qrCodeId: Scalars["String"]["input"];
};

export type MutationCancelBookingArgs = {
	bookingId: Scalars["String"]["input"];
};

export type MutationCreateAutomaticResponseArgs = {
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateHelpRequestResponseArgs = {
	language: Scalars["String"]["input"];
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateLocationArgs = {
	name: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateManualPaymentArgs = {
	bookingFeatureType: BookingFeatureType;
	capacity: Scalars["Int"]["input"];
	email: Scalars["String"]["input"];
	endsAt: Scalars["DateTimeISO"]["input"];
	startsAt: Scalars["DateTimeISO"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateManualSubscriptionArgs = {
	bookingFeatureType: BookingFeatureType;
	capacity: Scalars["Int"]["input"];
	email: Scalars["String"]["input"];
	startsAt: Scalars["DateTimeISO"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateMultipleLocationsArgs = {
	names: Array<Scalars["String"]["input"]>;
	venueId: Scalars["String"]["input"];
};

export type MutationCreatePaymentArgs = {
	capacity: Scalars["Int"]["input"];
	endsAt: Scalars["DateTimeISO"]["input"];
	startsAt: Scalars["DateTimeISO"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreatePermissionArgs = {
	input: CreatePermissionInput;
};

export type MutationCreatePositionBatchedArgs = {
	positions: Array<CreatePositionBatchedInput>;
	venueId: Scalars["String"]["input"];
};

export type MutationCreatePrintJobArgs = {
	input: CreatePrintJobInput;
};

export type MutationCreateQrCodeDynamicArgs = {
	receiver: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateQrCodeHelpArgs = {
	hasChat: Scalars["Boolean"]["input"];
	locationId: Scalars["String"]["input"];
	name?: InputMaybe<Scalars["String"]["input"]>;
	showQuestions: Scalars["Boolean"]["input"];
};

export type MutationCreateQrCodeInternalChatArgs = {
	hasPin: Scalars["Boolean"]["input"];
	locationId: Scalars["String"]["input"];
	name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateSubscriptionArgs = {
	capacity: Scalars["Int"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationCreateTestVenueArgs = {
	language: Scalars["String"]["input"];
	name: Scalars["String"]["input"];
	userId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateTrialArgs = {
	venueId: Scalars["String"]["input"];
};

export type MutationCreateVenueArgs = {
	details: VenueDetailsInput;
	name: Scalars["String"]["input"];
};

export type MutationDeleteLocationArgs = {
	id: Scalars["String"]["input"];
};

export type MutationDeletePermissionArgs = {
	permissionId: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationDeletePositionArgs = {
	id: Scalars["String"]["input"];
};

export type MutationDeleteUserArgs = {
	email: Scalars["String"]["input"];
};

export type MutationDeleteUserCodeConfirmationArgs = {
	code: Scalars["String"]["input"];
	id: Scalars["String"]["input"];
};

export type MutationDeleteUserTokenConfirmationArgs = {
	token: Scalars["String"]["input"];
};

export type MutationEditLocationNameArgs = {
	id: Scalars["String"]["input"];
	name: Scalars["String"]["input"];
};

export type MutationEditPermissionArgs = {
	permissionId: Scalars["String"]["input"];
	role: Roles;
	venueId: Scalars["String"]["input"];
};

export type MutationEditPositionArgs = {
	coordinates?: InputMaybe<CoordinatesInput>;
	id: Scalars["String"]["input"];
	name?: InputMaybe<Scalars["String"]["input"]>;
	venueId: Scalars["String"]["input"];
};

export type MutationEditPositionCoordinatesArgs = {
	coordinates: CoordinatesRequiredInput;
	positionId: Scalars["String"]["input"];
};

export type MutationEditPositionLocationArgs = {
	locationId: Scalars["String"]["input"];
	positionId: Scalars["String"]["input"];
};

export type MutationEditVenueActiveArgs = {
	endsAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
	isActive: Scalars["Boolean"]["input"];
	isRequired: Scalars["Boolean"]["input"];
	startsAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
	venueId: Scalars["String"]["input"];
};

export type MutationEditVenueDetailsArgs = {
	details: VenueDetailsInput;
	venueId: Scalars["String"]["input"];
};

export type MutationEditVenueImageArgs = {
	image: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationEditVenueNameArgs = {
	name: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationEditVenuePhoneNumberArgs = {
	phoneNumber: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationEditVenueSettingsInactivityTimeoutArgs = {
	inactivityTimeout: Scalars["Int"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationGetMagicLinkForAppArgs = {
	email: Scalars["String"]["input"];
};

export type MutationLogoutMobileArgs = {
	sessionId: Scalars["String"]["input"];
};

export type MutationReassignNfcTagToQrCodeArgs = {
	nfcTagId: Scalars["String"]["input"];
	qrCodeId: Scalars["String"]["input"];
};

export type MutationResetCurrentPositionArgs = {
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationResetVenueArchivedArgs = {
	venueId: Scalars["String"]["input"];
};

export type MutationRetryPrintJobArgs = {
	id: Scalars["String"]["input"];
};

export type MutationSendMailArgs = {
	subject: Scalars["String"]["input"];
	text: Scalars["String"]["input"];
};

export type MutationSendPasswordResetLinkArgs = {
	email: Scalars["String"]["input"];
};

export type MutationSetVenueArchivedArgs = {
	venueId: Scalars["String"]["input"];
};

export type MutationSetVenueDeletedArgs = {
	venueId: Scalars["String"]["input"];
};

export type MutationSetVenueGeofenceArgs = {
	input: SetVenueGeofenceInput;
};

export type MutationSetVenueGeofenceSettingsArgs = {
	input: SetVenueGeofenceSettingsInput;
};

export type MutationSetVenueSituationsSettingsArgs = {
	input: SetVenueSituationsSettingsInput;
};

export type MutationSignInWithIdTokenArgs = {
	idToken: Scalars["String"]["input"];
};

export type MutationSignupArgs = {
	email: Scalars["String"]["input"];
};

export type MutationSignupWithIdTokenArgs = {
	idToken: Scalars["String"]["input"];
};

export type MutationSubmitDynamicRequestArgs = {
	coordinates: CoordinatesInput;
	input: Array<SubmitDynamicRequestInput>;
	language: Scalars["String"]["input"];
	qrCodeId: Scalars["String"]["input"];
};

export type MutationSubmitHelpRequestArgs = {
	coordinates: CoordinatesInput;
	language: Scalars["String"]["input"];
	person?: InputMaybe<Person>;
	qrCodeId: Scalars["String"]["input"];
	situation?: InputMaybe<Situation>;
};

export type MutationSubmitInternalHelpRequestArgs = {
	language: Scalars["String"]["input"];
	qrCodeId: Scalars["String"]["input"];
	severe: Scalars["Boolean"]["input"];
};

export type MutationSubmitVenueHelpRequestArgs = {
	input: SubmitVenueHelpRequestInput;
};

export type MutationToggleIsActiveArgs = {
	isActive: Scalars["Boolean"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationToggleNotificationsArgs = {
	enabled: Scalars["Boolean"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationToggleVenueActiveFeedbackArgs = {
	venueId: Scalars["String"]["input"];
};

export type MutationUnassignNfcTagFromQrCodeArgs = {
	nfcTagId: Scalars["String"]["input"];
};

export type MutationUpdateCurrentPositionArgs = {
	coordinates: CoordinatesRequiredInput;
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationUpdateFcmTokenArgs = {
	token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateLanguageArgs = {
	language: Scalars["String"]["input"];
};

export type MutationUpdateLastCharacterTypedAtArgs = {
	input: UpdateTypingInput;
};

export type MutationUpdateLocationCoordinatesArgs = {
	lat: Scalars["Float"]["input"];
	lng: Scalars["Float"]["input"];
	locationId: Scalars["String"]["input"];
	radius?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateNameArgs = {
	name: Scalars["String"]["input"];
};

export type MutationUpdateNotificationChannelArgs = {
	input: UpdateNotificationChannelInput;
};

export type MutationUpdatePhoneNumberArgs = {
	phoneNumber: Scalars["String"]["input"];
};

export type MutationUpdateStepsArgs = {
	id: Scalars["String"]["input"];
	steps: Scalars["String"]["input"];
};

export type MutationUpdateVenueActiveFeedbackArgs = {
	input: UpdateVenueActiveFeedbackInput;
	venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueCoordinatesArgs = {
	lat: Scalars["Float"]["input"];
	lng: Scalars["Float"]["input"];
	radius?: InputMaybe<Scalars["Float"]["input"]>;
	venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeArgs = {
	colors: VenueThemeColorsInput;
	venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeTextArgs = {
	input: UpdateVenueThemeTextInput;
	venueId: Scalars["String"]["input"];
};

export type MutationUpdateVenueThemeTextBulkArgs = {
	input: Array<UpdateVenueThemeTextInput>;
	venueId: Scalars["String"]["input"];
};

export type MutationUpsertNotificationChannelArgs = {
	id?: InputMaybe<Scalars["String"]["input"]>;
	notificationChannelType: NotificationChannelType;
	receiver: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationVerifyIdTokenArgs = {
	idToken: Scalars["String"]["input"];
};

export type MutationVerifyMagicLinkArgs = {
	code: Scalars["String"]["input"];
	uuid: Scalars["String"]["input"];
};

export type MutationWriteMessageArgs = {
	text: Scalars["String"]["input"];
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type MutationWriteVenueFeedbackArgs = {
	input: Array<Array<VenueFeedbackInput>>;
	venueId: Scalars["String"]["input"];
};

export type NfcTag = {
	__typename?: "NFCTag";
	id: Scalars["String"]["output"];
	qrCode?: Maybe<QrCode>;
};

export type Notification = {
	__typename?: "Notification";
	enabled: Scalars["Boolean"]["output"];
	id: Scalars["String"]["output"];
	notificationChannels: Array<NotificationChannel>;
	timestamp?: Maybe<Timestamp>;
	type: NotificationType;
	typeId?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationChannel = {
	__typename?: "NotificationChannel";
	enabled: Scalars["Boolean"]["output"];
	errorAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	id: Scalars["String"]["output"];
	notification?: Maybe<Notification>;
	receiver: Scalars["String"]["output"];
	timestamp?: Maybe<Timestamp>;
	type: NotificationChannelType;
};

/** The channel for the notification */
export enum NotificationChannelType {
	Email = "email",
	Firebase = "firebase",
	Firestore = "firestore",
	Slack = "slack",
	Webhook = "webhook",
}

/** The type of the notification */
export enum NotificationType {
	Location = "location",
	Position = "position",
	QrCode = "qrCode",
	Venue = "venue",
}

export type OffsetType = {
	bottom: Scalars["Float"]["input"];
	left: Scalars["Float"]["input"];
};

export type PaginatedBookingResponse = {
	__typename?: "PaginatedBookingResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<Booking>;
	total: Scalars["Int"]["output"];
};

export type PaginatedBookingResult = ErrorReturn | PaginatedBookingResponse;

export type PaginatedHelpStreamResponse = {
	__typename?: "PaginatedHelpStreamResponse";
	items: Array<HelpRequest>;
	nextCursor?: Maybe<Scalars["String"]["output"]>;
	total: Scalars["Float"]["output"];
};

export type PaginatedPermissionResponse = {
	__typename?: "PaginatedPermissionResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<Permission>;
	total: Scalars["Int"]["output"];
};

export type PaginatedPrintJobResponse = {
	__typename?: "PaginatedPrintJobResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<PrintJob>;
	total: Scalars["Int"]["output"];
};

export type PaginatedQrCodeDynamicResponse = {
	__typename?: "PaginatedQRCodeDynamicResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<QrCodeDynamic>;
	total: Scalars["Int"]["output"];
};

export type PaginatedUserResponse = {
	__typename?: "PaginatedUserResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<User>;
	total: Scalars["Int"]["output"];
};

export type PaginatedVenueResponse = {
	__typename?: "PaginatedVenueResponse";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<Venue>;
	total: Scalars["Int"]["output"];
};

export type PaginationInput = {
	skip: Scalars["Int"]["input"];
	take: Scalars["Int"]["input"];
};

export type Permission = {
	__typename?: "Permission";
	id: Scalars["String"]["output"];
	role: Roles;
	/** @deprecated Use `role` instead */
	roles: Array<Roles>;
	scopes: Array<Scopes>;
	user: User;
	userId: Scalars["Int"]["output"];
	venue: Venue;
	venueId: Scalars["String"]["output"];
};

export type PermissionForVenueResult = ErrorReturn | PermissionForVenueSuccess;

export type PermissionForVenueSuccess = {
	__typename?: "PermissionForVenueSuccess";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<Permission>;
	total: Scalars["Int"]["output"];
};

/** The role of the person in the situation */
export enum Person {
	/** Jemand vom Personal hat etwas beobachtet. */
	Staff = "staff",
	/** Es gibt keine näheren Information. */
	Unknown = "unknown",
	/** Die Person ist selbst betroffen. */
	Victim = "victim",
	/** Die Person hat etwas beobachtet. */
	Witness = "witness",
}

export type Polygon = {
	__typename?: "Polygon";
	coordinates: Array<RequiredLatLng>;
	id: Scalars["String"]["output"];
};

export type PolygonInput = {
	coordinates: Array<LatLngRequiredInput>;
	id: Scalars["String"]["input"];
};

export type Position = {
	__typename?: "Position";
	coordinates?: Maybe<CoordinatesNonNull>;
	id: Scalars["String"]["output"];
	location: Location;
	name?: Maybe<Scalars["String"]["output"]>;
	qrCodes?: Maybe<Array<QrCode>>;
	timestamp: Timestamp;
};

export type Pricing = {
	__typename?: "Pricing";
	price: Scalars["Float"]["output"];
};

export type PrintJob = {
	__typename?: "PrintJob";
	format: Format;
	id: Scalars["String"]["output"];
	numberOfQRCodes: Scalars["Int"]["output"];
	path?: Maybe<Scalars["String"]["output"]>;
	size?: Maybe<Scalars["Int"]["output"]>;
	status: PrintJobStatus;
	template: Template;
	timestamp: Timestamp;
	venue: Venue;
};

export type PrintJobColorType = {
	background?: InputMaybe<ColorType>;
	foreground: ColorType;
};

export type PrintJobSettingsType = {
	margin: MarginType;
	offset: OffsetType;
	showCropMarks: Scalars["Boolean"]["input"];
	showCutContour: Scalars["Boolean"]["input"];
	size: Scalars["Float"]["input"];
};

export enum PrintJobStatus {
	Cancelled = "CANCELLED",
	Error = "ERROR",
	Pending = "PENDING",
	Printed = "PRINTED",
	Printing = "PRINTING",
}

export type PrintJobsInput = {
	formats: Array<Format>;
	venueId: Scalars["String"]["input"];
};

export type QrCode = {
	__typename?: "QRCode";
	id: Scalars["String"]["output"];
	lastScan?: Maybe<Scalars["DateTimeISO"]["output"]>;
	nfcTags?: Maybe<Array<NfcTag>>;
	notifications: Array<Notification>;
	position?: Maybe<Position>;
	qrCode: QrCodes;
	scanCount: Scalars["Int"]["output"];
	shortCode?: Maybe<Scalars["String"]["output"]>;
	timestamp?: Maybe<Timestamp>;
	venue?: Maybe<Venue>;
};

export type QrCodeDynamic = {
	__typename?: "QRCodeDynamic";
	id: Scalars["String"]["output"];
	qrCode: QrCode;
	rawJson: Scalars["String"]["output"];
	steps: Array<QrCodeDynamicStep>;
};

export type QrCodeDynamicBinaryChoiceComponent = {
	__typename?: "QRCodeDynamicBinaryChoiceComponent";
	display: QrCodeDynamicBinaryChoiceComponentDisplay;
	state: QrCodeDynamicBinaryChoiceComponentState;
};

export type QrCodeDynamicBinaryChoiceComponentDisplay = {
	__typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
	color: QrCodeDynamicColor;
	labels: QrCodeDynamicBinaryChoiceComponentDisplayLabels;
	margin?: Maybe<QrCodeDynamicMargin>;
	size: QrCodeDynamicSize;
};

export type QrCodeDynamicBinaryChoiceComponentDisplayLabels = {
	__typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
	option1: Translatable;
	option2: Translatable;
};

export type QrCodeDynamicBinaryChoiceComponentState = {
	__typename?: "QRCodeDynamicBinaryChoiceComponentState";
	key: Scalars["String"]["output"];
	next?: Maybe<Scalars["String"]["output"]>;
	values: QrCodeDynamicBinaryChoiceComponentStateValues;
};

export type QrCodeDynamicBinaryChoiceComponentStateValues = {
	__typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
	option1: Scalars["String"]["output"];
	option2: Scalars["String"]["output"];
};

export type QrCodeDynamicButtonComponent = {
	__typename?: "QRCodeDynamicButtonComponent";
	display: QrCodeDynamicButtonComponentDisplay;
	state: QrCodeDynamicButtonComponentState;
};

export type QrCodeDynamicButtonComponentDisplay = {
	__typename?: "QRCodeDynamicButtonComponentDisplay";
	color: QrCodeDynamicColor;
	label: Translatable;
	margin?: Maybe<QrCodeDynamicMargin>;
	size: QrCodeDynamicSize;
};

export type QrCodeDynamicButtonComponentSelectState = {
	__typename?: "QRCodeDynamicButtonComponentSelectState";
	key: Scalars["String"]["output"];
	next?: Maybe<Scalars["String"]["output"]>;
	value: Scalars["String"]["output"];
};

export type QrCodeDynamicButtonComponentState =
	| QrCodeDynamicButtonComponentSelectState
	| QrCodeDynamicButtonComponentSubmitState;

export type QrCodeDynamicButtonComponentSubmitState = {
	__typename?: "QRCodeDynamicButtonComponentSubmitState";
	key: Scalars["String"]["output"];
	next?: Maybe<Scalars["String"]["output"]>;
};

export enum QrCodeDynamicColor {
	Primary = "primary",
	Secondary = "secondary",
	TextPrimary = "textPrimary",
}

export type QrCodeDynamicFlexBoxComponent = {
	__typename?: "QRCodeDynamicFlexBoxComponent";
	display: QrCodeDynamicFlexBoxComponentDisplay;
};

export type QrCodeDynamicFlexBoxComponentDisplay = {
	__typename?: "QRCodeDynamicFlexBoxComponentDisplay";
	children: Array<QrCodeDynamicStepComponent>;
	margin?: Maybe<QrCodeDynamicMargin>;
};

export type QrCodeDynamicImageComponent = {
	__typename?: "QRCodeDynamicImageComponent";
	display: QrCodeDynamicImageComponentDisplay;
	key: Scalars["String"]["output"];
};

export type QrCodeDynamicImageComponentDisplay = {
	__typename?: "QRCodeDynamicImageComponentDisplay";
	margin?: Maybe<QrCodeDynamicMargin>;
	src: Scalars["String"]["output"];
};

export type QrCodeDynamicInfoComponent = {
	__typename?: "QRCodeDynamicInfoComponent";
	display: QrCodeDynamicInfoComponentDisplay;
};

export type QrCodeDynamicInfoComponentDisplay = {
	__typename?: "QRCodeDynamicInfoComponentDisplay";
	color: QrCodeDynamicColor;
	margin?: Maybe<QrCodeDynamicMargin>;
	text: Translatable;
	textAlign: QrCodeDynamicTextAlign;
	textVariant: QrCodeDynamicTextComponentVariant;
	title: Translatable;
	titleVariant: QrCodeDynamicTextComponentVariant;
};

export type QrCodeDynamicLinkComponent = {
	__typename?: "QRCodeDynamicLinkComponent";
	display: QrCodeDynamicLinkComponentDisplay;
	state: QrCodeDynamicLinkComponentState;
};

export type QrCodeDynamicLinkComponentDisplay = {
	__typename?: "QRCodeDynamicLinkComponentDisplay";
	color: QrCodeDynamicColor;
	label: Translatable;
	margin?: Maybe<QrCodeDynamicMargin>;
	size: QrCodeDynamicSize;
};

export type QrCodeDynamicLinkComponentState = {
	__typename?: "QRCodeDynamicLinkComponentState";
	external: Scalars["Boolean"]["output"];
	path: Scalars["String"]["output"];
};

export type QrCodeDynamicMargin = {
	__typename?: "QRCodeDynamicMargin";
	bottom?: Maybe<Scalars["Int"]["output"]>;
	left?: Maybe<Scalars["Int"]["output"]>;
	right?: Maybe<Scalars["Int"]["output"]>;
	top?: Maybe<Scalars["Int"]["output"]>;
};

export type QrCodeDynamicScrollBoxComponent = {
	__typename?: "QRCodeDynamicScrollBoxComponent";
	components: Array<QrCodeDynamicTextComponent>;
	display: QrCodeDynamicScrollBoxComponentDisplay;
	key: Scalars["String"]["output"];
};

export type QrCodeDynamicScrollBoxComponentDisplay = {
	__typename?: "QRCodeDynamicScrollBoxComponentDisplay";
	background?: Maybe<Scalars["String"]["output"]>;
	backgroundImage?: Maybe<Scalars["String"]["output"]>;
	margin?: Maybe<QrCodeDynamicMargin>;
};

export type QrCodeDynamicSelectComponent = {
	__typename?: "QRCodeDynamicSelectComponent";
	display: QrCodeDynamicSelectComponentDisplay;
	state: QrCodeDynamicSelectComponentState;
};

export type QrCodeDynamicSelectComponentDisplay = {
	__typename?: "QRCodeDynamicSelectComponentDisplay";
	color: QrCodeDynamicColor;
	labels: Array<Translatable>;
	type: QrCodeDynamicSelectComponentType;
};

export type QrCodeDynamicSelectComponentState = {
	__typename?: "QRCodeDynamicSelectComponentState";
	defaultValue?: Maybe<Scalars["String"]["output"]>;
	key: Scalars["String"]["output"];
	multiSelect: Scalars["Boolean"]["output"];
	next?: Maybe<Scalars["String"]["output"]>;
	required: Scalars["Boolean"]["output"];
	valueType: InputValueType;
	values: Array<Scalars["String"]["output"]>;
};

export enum QrCodeDynamicSelectComponentType {
	Button = "button",
	Checkbox = "checkbox",
	Radio = "radio",
}

export enum QrCodeDynamicSize {
	Large = "large",
	Medium = "medium",
	Small = "small",
}

export type QrCodeDynamicStep = {
	__typename?: "QRCodeDynamicStep";
	components: Array<QrCodeDynamicStepComponent>;
	cs: Array<QrCodeDynamicStepComponent>;
	key: Scalars["String"]["output"];
};

export type QrCodeDynamicStepComponent =
	| QrCodeDynamicBinaryChoiceComponent
	| QrCodeDynamicButtonComponent
	| QrCodeDynamicFlexBoxComponent
	| QrCodeDynamicImageComponent
	| QrCodeDynamicInfoComponent
	| QrCodeDynamicLinkComponent
	| QrCodeDynamicScrollBoxComponent
	| QrCodeDynamicSelectComponent
	| QrCodeDynamicTextBoxComponent
	| QrCodeDynamicTextComponent
	| QrCodeDynamicTextfieldComponent;

export enum QrCodeDynamicTextAlign {
	Center = "center",
	Left = "left",
	Right = "right",
}

export type QrCodeDynamicTextBoxComponent = {
	__typename?: "QRCodeDynamicTextBoxComponent";
	display: QrCodeDynamicTextBoxComponentDisplay;
};

export type QrCodeDynamicTextBoxComponentDisplay = {
	__typename?: "QRCodeDynamicTextBoxComponentDisplay";
	background?: Maybe<Scalars["String"]["output"]>;
	backgroundImage?: Maybe<Scalars["String"]["output"]>;
	color: QrCodeDynamicColor;
	margin?: Maybe<QrCodeDynamicMargin>;
	text: Translatable;
	textAlign: QrCodeDynamicTextAlign;
	variant: QrCodeDynamicTextComponentVariant;
};

export type QrCodeDynamicTextComponent = {
	__typename?: "QRCodeDynamicTextComponent";
	display: QrCodeDynamicTextComponentDisplay;
};

export type QrCodeDynamicTextComponentDisplay = {
	__typename?: "QRCodeDynamicTextComponentDisplay";
	color: QrCodeDynamicColor;
	margin?: Maybe<QrCodeDynamicMargin>;
	text: Translatable;
	textAlign: QrCodeDynamicTextAlign;
	variant: QrCodeDynamicTextComponentVariant;
};

export enum QrCodeDynamicTextComponentVariant {
	Caption = "caption",
	CopyLarge = "copyLarge",
	CopyLargeHero = "copyLargeHero",
	CopySmall = "copySmall",
	CopySmallHero = "copySmallHero",
	H1 = "h1",
	H2 = "h2",
	H3 = "h3",
	H4 = "h4",
	H5 = "h5",
	H6 = "h6",
}

export type QrCodeDynamicTextfieldComponent = {
	__typename?: "QRCodeDynamicTextfieldComponent";
	display: QrCodeDynamicTextfieldComponentDisplay;
	state: QrCodeDynamicTextfieldComponentState;
};

export type QrCodeDynamicTextfieldComponentDisplay = {
	__typename?: "QRCodeDynamicTextfieldComponentDisplay";
	label: Translatable;
	margin?: Maybe<QrCodeDynamicMargin>;
	multiline?: Maybe<Scalars["Boolean"]["output"]>;
	rows?: Maybe<Scalars["Int"]["output"]>;
};

export type QrCodeDynamicTextfieldComponentState = {
	__typename?: "QRCodeDynamicTextfieldComponentState";
	key: Scalars["String"]["output"];
};

export type QrCodeDynamicsResult = ErrorReturn | PaginatedQrCodeDynamicResponse;

export type QrCodeHelp = {
	__typename?: "QRCodeHelp";
	id: Scalars["String"]["output"];
	qrCode: QrCode;
	showQuestions: Scalars["Boolean"]["output"];
};

export type QrCodeInternalChat = {
	__typename?: "QRCodeInternalChat";
	hasPin: Scalars["Boolean"]["output"];
	id: Scalars["String"]["output"];
	qrCode: QrCode;
};

export type QrCodeReturn = ErrorReturn | QrCodeDynamic | QrCodeHelp | QrCodeInternalChat;

export type QrCodeTypeReturn = ErrorReturn | QrCodeTypeSuccessResult;

export type QrCodeTypeSuccessResult = {
	__typename?: "QRCodeTypeSuccessResult";
	qRCode: QrCode;
};

export type QrCodes = QrCodeDynamic | QrCodeHelp | QrCodeInternalChat;

export type Query = {
	__typename?: "Query";
	/**
	 *
	 *     # Get all bookings for all venues.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	bookings: PaginatedBookingResult;
	/**
	 *
	 *     # Get all bookings for a user.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	bookingsByUser: PaginatedBookingResult;
	/**
	 *
	 *     # Get all bookings for a venue.
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	bookingsByVenue: PaginatedBookingResult;
	chat: Chat;
	demoQRCode: QrCode;
	/**
	 *
	 *     # TODO: Add description
	 *
	 *     ## Used by
	 *
	 *     -
	 *
	 */
	getCurrentPosition?: Maybe<CurrentPosition>;
	/**
	 *
	 *     ## Used by
	 *
	 *     - Visitor (Web)
	 *
	 */
	getCurrentSecurityPositions: CurrentSecurityPositionsReturn;
	getMyPermissions: Array<Permission>;
	getMyPermissionsPaginated: PaginatedPermissionResponse;
	getMyVenues: Array<Venue>;
	getNotifications?: Maybe<Notification>;
	/** @deprecated Use `position` resolver for naming convention reason and current auth logic */
	getPosition: Position;
	/** @deprecated Once verified this is not in use anymore, remove */
	getPositionByQRCodeId: Position;
	/**
	 *
	 *     # getQRCode
	 *
	 *     Resolves a QR Code by the id of the QR Code parent entity
	 *
	 *     ## Used by
	 *
	 *     - Visitor v2 (Web)
	 *
	 */
	getQRCode: QrCodeTypeReturn;
	getQRCodeByShortCode?: Maybe<Scalars["String"]["output"]>;
	getStatistics: Statistics;
	getVenueFeedback: Array<VenueFeedback>;
	getVenueTheme?: Maybe<VenueTheme>;
	healthCheck: Scalars["String"]["output"];
	/**
	 *
	 *     # Get a list of help requests for a venue
	 *
	 *     ## Used by
	 *
	 *     - Owner (Web)
	 *
	 */
	helpStream?: Maybe<HelpStreamReturn>;
	me: MeResult;
	nearestVenueForTag?: Maybe<Scalars["String"]["output"]>;
	permissionsForVenue: PermissionForVenueResult;
	position: Position;
	pricing: Pricing;
	printJob: PrintJob;
	printJobs: PaginatedPrintJobResponse;
	qrCode: QrCode;
	qrCodeDynamic: QrCodeDynamic;
	qrCodeDynamicsByVenueId: QrCodeDynamicsResult;
	qrCodeType: QrCodeReturn;
	users: PaginatedUserResponse;
	venue: Venue;
	venueBySlug?: Maybe<Venue>;
	/**
	 *
	 *     # Get a paginated list of venues
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	venues: PaginatedVenueResponse;
	/**
	 *
	 *     # Get a paginated list of archived or deleted venues
	 *
	 *     ## Used by
	 *
	 *     - Admin (Web)
	 *
	 */
	venuesArchive: PaginatedVenueResponse;
	venuesWithCoordinates: VenueWithCoordinatesResult;
	verifyGeolocation: Scalars["Boolean"]["output"];
};

export type QueryBookingsArgs = {
	filter?: InputMaybe<BookingFilterInput>;
	pagination: PaginationInput;
};

export type QueryBookingsByUserArgs = {
	filter?: InputMaybe<BookingFilterInput>;
	pagination: PaginationInput;
};

export type QueryBookingsByVenueArgs = {
	pagination: PaginationInput;
	venueId: Scalars["String"]["input"];
};

export type QueryChatArgs = {
	helpRequestId: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type QueryDemoQrCodeArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryGetCurrentPositionArgs = {
	uuid: Scalars["String"]["input"];
};

export type QueryGetCurrentSecurityPositionsArgs = {
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type QueryGetMyPermissionsPaginatedArgs = {
	name?: InputMaybe<Scalars["String"]["input"]>;
	skip: Scalars["Int"]["input"];
	tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
	take: Scalars["Int"]["input"];
};

export type QueryGetNotificationsArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryGetPositionArgs = {
	positionId: Scalars["String"]["input"];
};

export type QueryGetPositionByQrCodeIdArgs = {
	qrCodeId: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type QueryGetQrCodeArgs = {
	id: Scalars["String"]["input"];
};

export type QueryGetQrCodeByShortCodeArgs = {
	shortCode: Scalars["String"]["input"];
};

export type QueryGetVenueFeedbackArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryGetVenueThemeArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryHelpStreamArgs = {
	cursor?: InputMaybe<Scalars["String"]["input"]>;
	venueId: Scalars["String"]["input"];
};

export type QueryNearestVenueForTagArgs = {
	coordinates: LatLngRequiredInput;
	tag: Scalars["String"]["input"];
};

export type QueryPermissionsForVenueArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryPositionArgs = {
	id: Scalars["String"]["input"];
};

export type QueryPricingArgs = {
	capacity: Scalars["Int"]["input"];
	item: Items;
};

export type QueryPrintJobArgs = {
	id: Scalars["String"]["input"];
};

export type QueryPrintJobsArgs = {
	input: PrintJobsInput;
	pagination: PaginationInput;
};

export type QueryQrCodeArgs = {
	qrCodeId: Scalars["String"]["input"];
};

export type QueryQrCodeDynamicArgs = {
	id: Scalars["String"]["input"];
};

export type QueryQrCodeDynamicsByVenueIdArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryQrCodeTypeArgs = {
	id: Scalars["String"]["input"];
};

export type QueryUsersArgs = {
	skip: Scalars["Int"]["input"];
	take: Scalars["Int"]["input"];
};

export type QueryVenueArgs = {
	venueId: Scalars["String"]["input"];
};

export type QueryVenueBySlugArgs = {
	slug: Scalars["String"]["input"];
};

export type QueryVenuesArgs = {
	name?: InputMaybe<Scalars["String"]["input"]>;
	skip: Scalars["Int"]["input"];
	tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
	take: Scalars["Int"]["input"];
	withArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
	withDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryVenuesArchiveArgs = {
	skip: Scalars["Int"]["input"];
	take: Scalars["Int"]["input"];
};

export type QueryVenuesWithCoordinatesArgs = {
	name?: InputMaybe<Scalars["String"]["input"]>;
	skip: Scalars["Int"]["input"];
	tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
	take: Scalars["Int"]["input"];
	withArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
	withDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryVerifyGeolocationArgs = {
	input: VerifyGeolocationInput;
};

export type ReassignNfcTagToQrCodeResult = ErrorReturn | NfcTag;

export type RegisterResult = UnknownError | User | UserAlreadyExistsError;

export type RequiredLatLng = {
	__typename?: "RequiredLatLng";
	lat: Scalars["Float"]["output"];
	lng: Scalars["Float"]["output"];
};

/** The role of the user */
export enum Roles {
	Admin = "admin",
	Awareness = "awareness",
	AwarenessTeam = "awarenessTeam",
	Owner = "owner",
	Security = "security",
	SecurityTeam = "securityTeam",
	Visitor = "visitor",
}

/** The scope of the user */
export enum Scopes {
	Admin = "admin",
	AwarenessRead = "awarenessRead",
	AwarenessWrite = "awarenessWrite",
	PermissionRead = "permissionRead",
	PermissionWrite = "permissionWrite",
	SecurityRead = "securityRead",
	SecurityWrite = "securityWrite",
	VenueRead = "venueRead",
	VenueWrite = "venueWrite",
	VenueWriteActivateEdit = "venueWriteActivateEdit",
	VisitorRead = "visitorRead",
	VisitorWrite = "visitorWrite",
}

export type SendPasswordResetLinkReturnType = ErrorReturn | SendPasswordResetLinkSuccessResult;

export type SendPasswordResetLinkSuccessResult = {
	__typename?: "SendPasswordResetLinkSuccessResult";
	email: Scalars["String"]["output"];
};

export type SetVenueGeofenceInput = {
	areas: GeofenceAreaInput;
	venueId: Scalars["String"]["input"];
};

export type SetVenueGeofenceResult = ErrorReturn | Geofencing;

export type SetVenueGeofenceSettingsInput = {
	requirePosition: Scalars["Boolean"]["input"];
	requirePositionForQRCodes: Scalars["Boolean"]["input"];
	requirePositionForVenue: Scalars["Boolean"]["input"];
	venueId: Scalars["String"]["input"];
};

export type SetVenueSituationsSettingsInput = {
	situations: Array<Situation>;
	venueId: Scalars["String"]["input"];
};

export type SetVenueSituationsSettingsResult = ErrorReturn | VenueSettings;

export type SignInWithIdTokenReturnType =
	| ErrorReturn
	| SignInWithIdTokenSuccessResult
	| UserNotFoundError;

export type SignInWithIdTokenSuccessResult = {
	__typename?: "SignInWithIdTokenSuccessResult";
	accessToken: Scalars["String"]["output"];
	sessionId: Scalars["String"]["output"];
};

/** The situation in which the person is */
export enum Situation {
	/** Jemand wird angegriffen. */
	Attacked = "attacked",
	Discriminated = "discriminated",
	Emergency = "emergency",
	/** Jemand wird belästigt. */
	Harassed = "harassed",
	Notify = "notify",
	/** Jemand fühlt sich unwohl. */
	SomethingWrong = "somethingWrong",
	/** Es gibt keine näheren Information. */
	Unknown = "unknown",
}

export type Statistics = {
	__typename?: "Statistics";
	venues: Array<VenueStatistics>;
};

export enum StatusCodes {
	Conflict = "CONFLICT",
	Forbidden = "FORBIDDEN",
	InternalServerError = "INTERNAL_SERVER_ERROR",
	NotFound = "NOT_FOUND",
	Unauthorized = "UNAUTHORIZED",
}

export type SubmitDynamicRequestInput = {
	key: Scalars["String"]["input"];
	value: Scalars["String"]["input"];
	valueType: InputValueType;
};

export type SubmitVenueHelpRequestInput = {
	coordinates?: InputMaybe<CoordinatesInput>;
	language: Scalars["String"]["input"];
	location?: InputMaybe<Scalars["String"]["input"]>;
	person: Person;
	position?: InputMaybe<Scalars["String"]["input"]>;
	situation: Situation;
	venueId: Scalars["String"]["input"];
};

export type Subscription = {
	__typename?: "Subscription";
	getHelpRequestBouncerResponse: Scalars["String"]["output"];
	newChatMessage: ChatMessage;
	onTypingStatusChanged: Scalars["DateTimeISO"]["output"];
};

export type SubscriptionGetHelpRequestBouncerResponseArgs = {
	uuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubscriptionNewChatMessageArgs = {
	uuid: Scalars["String"]["input"];
};

export type SubscriptionOnTypingStatusChangedArgs = {
	helpRequestId: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
};

export type SuccessOrErrorReturnResult = ErrorReturn | SuccessReturn;

export type SuccessReturn = {
	__typename?: "SuccessReturn";
	success: Scalars["Boolean"]["output"];
};

export enum Template {
	Custom = "CUSTOM",
	Default = "DEFAULT",
}

export type Timestamp = {
	__typename?: "Timestamp";
	createdAt: Scalars["DateTimeISO"]["output"];
	deletedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	updatedAt: Scalars["DateTimeISO"]["output"];
};

export type Translatable = {
	__typename?: "Translatable";
	/** German */
	de: Scalars["String"]["output"];
	/** English */
	en: Scalars["String"]["output"];
};

export type TranslatableInput = {
	/** German */
	de: Scalars["String"]["input"];
	/** English */
	en: Scalars["String"]["input"];
};

export type Translations = {
	__typename?: "Translations";
	/** The first message, that will be shown to the visitor automatically */
	automaticFirstMessage?: Maybe<Scalars["String"]["output"]>;
	/** The first message, that will be shown to the visitor automatically, if no member reacted within XX seconds (currently direct chat only) */
	automaticUnavailableMessage?: Maybe<Scalars["String"]["output"]>;
	/** // TODO */
	personStepHeadline?: Maybe<Scalars["String"]["output"]>;
	/** // TODO */
	personStepSubtitle?: Maybe<Scalars["String"]["output"]>;
	/** // TODO */
	situationStepSubtitle?: Maybe<Scalars["String"]["output"]>;
	/** Title that is being displayed after the visitor scanned a code */
	waitingHeadline?: Maybe<Scalars["String"]["output"]>;
	/** Title that is being after a security member accepted the call */
	waitingHeadlineAccepted?: Maybe<Scalars["String"]["output"]>;
	/** Message that is being displayed after the visitor scanned a code */
	waitingMessage?: Maybe<Scalars["String"]["output"]>;
	/** Message that is being displayed after a security member accepted the call */
	waitingMessageAccepted?: Maybe<Scalars["String"]["output"]>;
};

export type TranslationsInput = {
	/** German translation */
	de?: InputMaybe<Scalars["String"]["input"]>;
	/** English translation */
	en?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnassignNfcTagFromQrCodeResult = ErrorReturn | NfcTag;

/** Indicates an error due to unknown reasons. */
export type UnknownError = BackendError & {
	__typename?: "UnknownError";
	i18nKey: Scalars["String"]["output"];
	message: Scalars["String"]["output"];
	statusCode: StatusCodes;
};

export type UpdateNotificationChannelInput = {
	enabled: Scalars["Boolean"]["input"];
	id: Scalars["String"]["input"];
	receiver: Scalars["String"]["input"];
	type: NotificationChannelType;
};

export type UpdateTypingInput = {
	helpRequestId: Scalars["String"]["input"];
	userType: UserType;
	venueId: Scalars["String"]["input"];
};

export type UpdateUserResult = ErrorReturn | User;

export type UpdateVenueActiveFeedbackInput = {
	button: TranslatableInput;
	link: Scalars["String"]["input"];
	text: TranslatableInput;
};

export type UpdateVenueThemeTextInput = {
	text: TranslationsInput;
	type: Scalars["String"]["input"];
};

export type User = {
	__typename?: "User";
	email: Scalars["String"]["output"];
	fcmToken?: Maybe<Scalars["String"]["output"]>;
	id: Scalars["String"]["output"];
	image?: Maybe<Scalars["String"]["output"]>;
	isVerified: Scalars["Boolean"]["output"];
	language: Scalars["String"]["output"];
	lastLogin?: Maybe<Scalars["DateTimeISO"]["output"]>;
	lastRefresh?: Maybe<Scalars["DateTimeISO"]["output"]>;
	name?: Maybe<Scalars["String"]["output"]>;
	permissions: Array<Permission>;
	phoneNumber?: Maybe<Scalars["String"]["output"]>;
	role: Roles;
	stripeCustomer?: Maybe<Scalars["String"]["output"]>;
	timestamp: Timestamp;
	venues: Array<Venue>;
};

/** Indicates, that a user with the given email address already exists. */
export type UserAlreadyExistsError = BackendError & {
	__typename?: "UserAlreadyExistsError";
	i18nKey: Scalars["String"]["output"];
	message: Scalars["String"]["output"];
	statusCode: StatusCodes;
};

/** Indicates, that a user with the given email address already exists. */
export type UserNotFoundError = BackendError & {
	__typename?: "UserNotFoundError";
	i18nKey: Scalars["String"]["output"];
	message: Scalars["String"]["output"];
	statusCode: StatusCodes;
};

export enum UserType {
	Staff = "staff",
	User = "user",
}

export type Venue = {
	__typename?: "Venue";
	active: VenueActive;
	activeSubscription?: Maybe<Booking>;
	archivedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	/** Returns the booking feature type of the venue */
	bookingFeatureType: BookingFeatureType;
	bookings: Array<Booking>;
	contactNumber?: Maybe<Scalars["String"]["output"]>;
	contactUser?: Maybe<User>;
	coordinates?: Maybe<CoordinatesNonNull>;
	details: VenueDetails;
	geofencing: Geofencing;
	hasActiveLead?: Maybe<Scalars["Boolean"]["output"]>;
	hasActivePaidSubscription: Scalars["Boolean"]["output"];
	id: Scalars["String"]["output"];
	image: Scalars["String"]["output"];
	isActive: Scalars["Boolean"]["output"];
	locations: Array<Location>;
	mayToggleActivate: Scalars["Boolean"]["output"];
	name: Scalars["String"]["output"];
	permission: Permission;
	permissions: Array<Permission>;
	phoneNumber?: Maybe<Scalars["String"]["output"]>;
	settings: VenueSettings;
	slug?: Maybe<Scalars["String"]["output"]>;
	theme?: Maybe<VenueTheme>;
	timestamp: Timestamp;
};

export type VenueActive = {
	__typename?: "VenueActive";
	endsAt?: Maybe<Scalars["String"]["output"]>;
	feedback?: Maybe<VenueActiveFeedback>;
	isActive: Scalars["Boolean"]["output"];
	isRequired: Scalars["Boolean"]["output"];
	startsAt?: Maybe<Scalars["String"]["output"]>;
};

export type VenueActiveFeedback = {
	__typename?: "VenueActiveFeedback";
	button: Translatable;
	link: Scalars["String"]["output"];
	text: Translatable;
};

export type VenueDetails = {
	__typename?: "VenueDetails";
	capacity?: Maybe<Scalars["Int"]["output"]>;
	endsAt?: Maybe<Scalars["String"]["output"]>;
	startsAt?: Maybe<Scalars["String"]["output"]>;
	type?: Maybe<VenueType>;
	weeklyOpen?: Maybe<Scalars["Boolean"]["output"]>;
};

export type VenueDetailsInput = {
	capacity: Scalars["Int"]["input"];
	endsAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
	startsAt?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
	type: VenueType;
	weeklyOpen: Scalars["Boolean"]["input"];
};

export type VenueFeedback = {
	__typename?: "VenueFeedback";
	feedback: Array<Array<VenueFeedbackObject>>;
	id: Scalars["String"]["output"];
	timestamp: Timestamp;
	venue: Venue;
};

export type VenueFeedbackInput = {
	key: Scalars["String"]["input"];
	label: Scalars["String"]["input"];
	value: Scalars["String"]["input"];
};

export type VenueFeedbackObject = {
	__typename?: "VenueFeedbackObject";
	key: Scalars["String"]["output"];
	label: Scalars["String"]["output"];
	value: Scalars["String"]["output"];
};

export type VenueQrStatistics = {
	__typename?: "VenueQRStatistics";
	external: Scalars["Float"]["output"];
	internal: Scalars["Float"]["output"];
};

export type VenueSettings = {
	__typename?: "VenueSettings";
	allowDirectChat: Scalars["Boolean"]["output"];
	enablePositionSharing: Scalars["Boolean"]["output"];
	hasChat: Scalars["Boolean"]["output"];
	inactivityTimeout: Scalars["Int"]["output"];
	isWhiteLabel: Scalars["Boolean"]["output"];
	requirePosition: Scalars["Boolean"]["output"];
	requirePositionForQRCodes: Scalars["Boolean"]["output"];
	requirePositionForVenue: Scalars["Boolean"]["output"];
	showQuestions: Scalars["Boolean"]["output"];
	situations: Array<Situation>;
};

export type VenueStatistics = {
	__typename?: "VenueStatistics";
	helpStream?: Maybe<HelpStream>;
	last24Hours: VenueQrStatistics;
	last28Days: VenueQrStatistics;
	lastWeek: VenueQrStatistics;
	lastYear: VenueQrStatistics;
	venue: Venue;
};

export type VenueTheme = {
	__typename?: "VenueTheme";
	colors: VenueThemeColors;
	id: Scalars["String"]["output"];
	images?: Maybe<VenueThemeImages>;
	localizations: VenueThemeTexts;
	texts: Translations;
	timestamp?: Maybe<Timestamp>;
	venue: Venue;
};

export type VenueThemeTextsArgs = {
	language: Scalars["String"]["input"];
};

export type VenueThemeColors = {
	__typename?: "VenueThemeColors";
	background: Scalars["String"]["output"];
	primaryColor: Scalars["String"]["output"];
	secondaryColor: Scalars["String"]["output"];
};

export type VenueThemeColorsInput = {
	background: Scalars["String"]["input"];
	primaryColor: Scalars["String"]["input"];
	secondaryColor: Scalars["String"]["input"];
};

export type VenueThemeImages = {
	__typename?: "VenueThemeImages";
	background?: Maybe<Scalars["String"]["output"]>;
	logo?: Maybe<Scalars["String"]["output"]>;
};

export type VenueThemeTexts = {
	__typename?: "VenueThemeTexts";
	/** German */
	de: Translations;
	/** English */
	en: Translations;
};

export enum VenueType {
	Bar = "bar",
	Club = "club",
	Event = "event",
	Festival = "festival",
	Other = "other",
	Stadium = "stadium",
}

export type VenueWithCoordinates = {
	__typename?: "VenueWithCoordinates";
	active: VenueActive;
	activeSubscription?: Maybe<Booking>;
	archivedAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
	/** Returns the booking feature type of the venue */
	bookingFeatureType: BookingFeatureType;
	bookings: Array<Booking>;
	contactNumber?: Maybe<Scalars["String"]["output"]>;
	contactUser?: Maybe<User>;
	coordinates: WithCoordinates;
	details: VenueDetails;
	geofencing: Geofencing;
	hasActiveLead?: Maybe<Scalars["Boolean"]["output"]>;
	hasActivePaidSubscription: Scalars["Boolean"]["output"];
	id: Scalars["String"]["output"];
	image: Scalars["String"]["output"];
	isActive: Scalars["Boolean"]["output"];
	locations: Array<Location>;
	mayToggleActivate: Scalars["Boolean"]["output"];
	name: Scalars["String"]["output"];
	permission: Permission;
	permissions: Array<Permission>;
	phoneNumber?: Maybe<Scalars["String"]["output"]>;
	settings: VenueSettings;
	slug?: Maybe<Scalars["String"]["output"]>;
	theme?: Maybe<VenueTheme>;
	timestamp: Timestamp;
};

export type VenueWithCoordinatesResult = ErrorReturn | VenueWithCoordinatesSuccessResult;

export type VenueWithCoordinatesSuccessResult = {
	__typename?: "VenueWithCoordinatesSuccessResult";
	hasMore: Scalars["Boolean"]["output"];
	items: Array<VenueWithCoordinates>;
	total: Scalars["Int"]["output"];
};

export type VerifyGeolocationInput = {
	coordinates: LatLngRequiredInput;
	venueId: Scalars["String"]["input"];
};

export type VerifyIdTokenResult = {
	__typename?: "VerifyIdTokenResult";
	accessToken: Scalars["String"]["output"];
	sessionId: Scalars["String"]["output"];
};

export type VerifyIdTokenReturnType = ErrorReturn | VerifyIdTokenResult;

export type VerifyMagicLinkResult = ErrorReturn | VerifyMagicLinkReturn;

export type VerifyMagicLinkReturn = {
	__typename?: "VerifyMagicLinkReturn";
	accessToken: Scalars["String"]["output"];
	sessionId: Scalars["String"]["output"];
};

export type WithCoordinates = {
	__typename?: "WithCoordinates";
	altitude: Scalars["Float"]["output"];
	lat: Scalars["Float"]["output"];
	lng: Scalars["Float"]["output"];
	radius: Scalars["Float"]["output"];
};

export type OnTypingStatusChangedSubscriptionVariables = Exact<{
	helpRequestId: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
}>;

export type OnTypingStatusChangedSubscription = {
	__typename?: "Subscription";
	onTypingStatusChanged: string;
};

export type GetCurrentSecurityPositionsQueryVariables = Exact<{
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
}>;

export type GetCurrentSecurityPositionsQuery = {
	__typename?: "Query";
	getCurrentSecurityPositions:
		| {
				__typename: "CurrentSecurityPositionsSuccessResult";
				positions: Array<{ __typename?: "RequiredLatLng"; lat: number; lng: number }>;
		  }
		| { __typename: "ErrorReturn"; message: string; statusCode: StatusCodes; i18nKey: string };
};

export type UpdateLastCharacterTypedAtMutationVariables = Exact<{
	input: UpdateTypingInput;
}>;

export type UpdateLastCharacterTypedAtMutation = {
	__typename?: "Mutation";
	updateLastCharacterTypedAt: boolean;
};

export type VerifyGeolocationQueryVariables = Exact<{
	input: VerifyGeolocationInput;
}>;

export type VerifyGeolocationQuery = { __typename?: "Query"; verifyGeolocation: boolean };

export type CreateAutomaticResponseMutationVariables = Exact<{
	venueId: Scalars["String"]["input"];
	uuid: Scalars["String"]["input"];
}>;

export type CreateAutomaticResponseMutation = {
	__typename?: "Mutation";
	createAutomaticResponse:
		| { __typename?: "ErrorReturn"; message: string }
		| { __typename?: "SuccessReturn"; success: boolean };
};

export type GetCurrentPositionQueryVariables = Exact<{
	uuid: Scalars["String"]["input"];
}>;

export type GetCurrentPositionQuery = {
	__typename?: "Query";
	getCurrentPosition?: {
		__typename?: "CurrentPosition";
		createdAt: string;
		updatedAt: string;
		lat: number;
		lng: number;
	} | null;
};

export type GetHelpRequestBouncerResponseSubscriptionVariables = Exact<{
	uuid?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetHelpRequestBouncerResponseSubscription = {
	__typename?: "Subscription";
	getHelpRequestBouncerResponse: string;
};

export type ResetCurrentPositionMutationVariables = Exact<{
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
}>;

export type ResetCurrentPositionMutation = {
	__typename?: "Mutation";
	resetCurrentPosition: boolean;
};

export type SendMailMutationVariables = Exact<{
	text: Scalars["String"]["input"];
	subject: Scalars["String"]["input"];
}>;

export type SendMailMutation = {
	__typename?: "Mutation";
	sendMail: { __typename?: "SuccessReturn"; success: boolean };
};

export type SubmitHelpRequestMutationVariables = Exact<{
	qrCodeId: Scalars["String"]["input"];
	coordinates: CoordinatesInput;
	person?: InputMaybe<Person>;
	situation?: InputMaybe<Situation>;
	language: Scalars["String"]["input"];
}>;

export type SubmitHelpRequestMutation = { __typename?: "Mutation"; submitHelpRequest: string };

export type SubmitInternalHelpRequestMutationVariables = Exact<{
	qrCodeId: Scalars["String"]["input"];
	severe: Scalars["Boolean"]["input"];
	language: Scalars["String"]["input"];
}>;

export type SubmitInternalHelpRequestMutation = {
	__typename?: "Mutation";
	submitInternalHelpRequest: string;
};

export type UpdateCurrentPositionMutationVariables = Exact<{
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
	coordinates: CoordinatesRequiredInput;
}>;

export type UpdateCurrentPositionMutation = {
	__typename?: "Mutation";
	updateCurrentPosition: string;
};

export type WriteMessageMutationVariables = Exact<{
	venueId: Scalars["String"]["input"];
	uuid: Scalars["String"]["input"];
	text: Scalars["String"]["input"];
}>;

export type WriteMessageMutation = {
	__typename?: "Mutation";
	writeMessage: {
		__typename: "ChatMessage";
		id: string;
		date: string;
		sender?: string | null;
		text: string;
	};
};

export type WriteVenueFeedbackMutationVariables = Exact<{
	venueId: Scalars["String"]["input"];
	input:
		| Array<Array<VenueFeedbackInput> | VenueFeedbackInput>
		| Array<VenueFeedbackInput>
		| VenueFeedbackInput;
}>;

export type WriteVenueFeedbackMutation = { __typename?: "Mutation"; writeVenueFeedback: boolean };

export type GetQrCodeByShortCodeQueryVariables = Exact<{
	shortCode: Scalars["String"]["input"];
}>;

export type GetQrCodeByShortCodeQuery = {
	__typename?: "Query";
	getQRCodeByShortCode?: string | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
	__typename?: "Query";
	me:
		| { __typename: "Anonymous"; uuid: string }
		| { __typename: "ErrorReturn"; message: string }
		| { __typename: "User" };
};

export type QrCodeDynamicStepsQueryVariables = Exact<{
	id: Scalars["String"]["input"];
}>;

export type QrCodeDynamicStepsQuery = {
	__typename?: "Query";
	qrCodeDynamic: {
		__typename?: "QRCodeDynamic";
		steps: Array<{
			__typename: "QRCodeDynamicStep";
			key: string;
			cs: Array<
				| {
						__typename: "QRCodeDynamicBinaryChoiceComponent";
						state: {
							__typename?: "QRCodeDynamicBinaryChoiceComponentState";
							key: string;
							next?: string | null;
							values: {
								__typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
								option1: string;
								option2: string;
							};
						};
						display: {
							__typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
							color: QrCodeDynamicColor;
							size: QrCodeDynamicSize;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							labels: {
								__typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
								option1: { __typename?: "Translatable"; de: string; en: string };
								option2: { __typename?: "Translatable"; de: string; en: string };
							};
						};
				  }
				| {
						__typename: "QRCodeDynamicButtonComponent";
						state:
							| {
									__typename: "QRCodeDynamicButtonComponentSelectState";
									key: string;
									value: string;
									next?: string | null;
							  }
							| {
									__typename: "QRCodeDynamicButtonComponentSubmitState";
									key: string;
									next?: string | null;
							  };
						display: {
							__typename?: "QRCodeDynamicButtonComponentDisplay";
							color: QrCodeDynamicColor;
							size: QrCodeDynamicSize;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							label: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
				| {
						__typename: "QRCodeDynamicFlexBoxComponent";
						display: {
							__typename: "QRCodeDynamicFlexBoxComponentDisplay";
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							children: Array<
								| {
										__typename: "QRCodeDynamicBinaryChoiceComponent";
										state: {
											__typename?: "QRCodeDynamicBinaryChoiceComponentState";
											key: string;
											next?: string | null;
											values: {
												__typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
												option1: string;
												option2: string;
											};
										};
										display: {
											__typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
											color: QrCodeDynamicColor;
											size: QrCodeDynamicSize;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											labels: {
												__typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
												option1: { __typename?: "Translatable"; de: string; en: string };
												option2: { __typename?: "Translatable"; de: string; en: string };
											};
										};
								  }
								| {
										__typename: "QRCodeDynamicButtonComponent";
										state:
											| {
													__typename: "QRCodeDynamicButtonComponentSelectState";
													key: string;
													value: string;
													next?: string | null;
											  }
											| {
													__typename: "QRCodeDynamicButtonComponentSubmitState";
													key: string;
													next?: string | null;
											  };
										display: {
											__typename?: "QRCodeDynamicButtonComponentDisplay";
											color: QrCodeDynamicColor;
											size: QrCodeDynamicSize;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											label: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
								| { __typename: "QRCodeDynamicFlexBoxComponent" }
								| {
										__typename: "QRCodeDynamicImageComponent";
										display: {
											__typename?: "QRCodeDynamicImageComponentDisplay";
											src: string;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
										};
								  }
								| {
										__typename: "QRCodeDynamicInfoComponent";
										display: {
											__typename?: "QRCodeDynamicInfoComponentDisplay";
											color: QrCodeDynamicColor;
											textAlign: QrCodeDynamicTextAlign;
											titleVariant: QrCodeDynamicTextComponentVariant;
											textVariant: QrCodeDynamicTextComponentVariant;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											title: { __typename?: "Translatable"; de: string; en: string };
											text: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
								| {
										__typename: "QRCodeDynamicLinkComponent";
										state: {
											__typename?: "QRCodeDynamicLinkComponentState";
											external: boolean;
											path: string;
										};
										display: {
											__typename?: "QRCodeDynamicLinkComponentDisplay";
											color: QrCodeDynamicColor;
											size: QrCodeDynamicSize;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											label: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
								| {
										__typename: "QRCodeDynamicScrollBoxComponent";
										key: string;
										components: Array<{
											__typename?: "QRCodeDynamicTextComponent";
											display: {
												__typename?: "QRCodeDynamicTextComponentDisplay";
												variant: QrCodeDynamicTextComponentVariant;
												color: QrCodeDynamicColor;
												textAlign: QrCodeDynamicTextAlign;
												margin?: {
													__typename?: "QRCodeDynamicMargin";
													top?: number | null;
													right?: number | null;
													bottom?: number | null;
													left?: number | null;
												} | null;
												text: { __typename?: "Translatable"; de: string; en: string };
											};
										}>;
										display: {
											__typename?: "QRCodeDynamicScrollBoxComponentDisplay";
											background?: string | null;
											backgroundImage?: string | null;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
										};
								  }
								| {
										__typename: "QRCodeDynamicSelectComponent";
										selectState: {
											__typename?: "QRCodeDynamicSelectComponentState";
											key: string;
											next?: string | null;
											required: boolean;
											defaultValue?: string | null;
											values: Array<string>;
											valueType: InputValueType;
										};
										selectDisplay: {
											__typename?: "QRCodeDynamicSelectComponentDisplay";
											type: QrCodeDynamicSelectComponentType;
											color: QrCodeDynamicColor;
											labels: Array<{ __typename?: "Translatable"; de: string; en: string }>;
										};
								  }
								| {
										__typename: "QRCodeDynamicTextBoxComponent";
										display: {
											__typename?: "QRCodeDynamicTextBoxComponentDisplay";
											variant: QrCodeDynamicTextComponentVariant;
											color: QrCodeDynamicColor;
											background?: string | null;
											backgroundImage?: string | null;
											textAlign: QrCodeDynamicTextAlign;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											text: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
								| {
										__typename: "QRCodeDynamicTextComponent";
										display: {
											__typename?: "QRCodeDynamicTextComponentDisplay";
											variant: QrCodeDynamicTextComponentVariant;
											color: QrCodeDynamicColor;
											textAlign: QrCodeDynamicTextAlign;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											text: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
								| {
										__typename: "QRCodeDynamicTextfieldComponent";
										state: { __typename?: "QRCodeDynamicTextfieldComponentState"; key: string };
										display: {
											__typename?: "QRCodeDynamicTextfieldComponentDisplay";
											multiline?: boolean | null;
											rows?: number | null;
											margin?: {
												__typename?: "QRCodeDynamicMargin";
												top?: number | null;
												right?: number | null;
												bottom?: number | null;
												left?: number | null;
											} | null;
											label: { __typename?: "Translatable"; de: string; en: string };
										};
								  }
							>;
						};
				  }
				| {
						__typename: "QRCodeDynamicImageComponent";
						display: {
							__typename?: "QRCodeDynamicImageComponentDisplay";
							src: string;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
						};
				  }
				| {
						__typename: "QRCodeDynamicInfoComponent";
						display: {
							__typename?: "QRCodeDynamicInfoComponentDisplay";
							color: QrCodeDynamicColor;
							textAlign: QrCodeDynamicTextAlign;
							titleVariant: QrCodeDynamicTextComponentVariant;
							textVariant: QrCodeDynamicTextComponentVariant;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							title: { __typename?: "Translatable"; de: string; en: string };
							text: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
				| {
						__typename: "QRCodeDynamicLinkComponent";
						state: {
							__typename?: "QRCodeDynamicLinkComponentState";
							external: boolean;
							path: string;
						};
						display: {
							__typename?: "QRCodeDynamicLinkComponentDisplay";
							color: QrCodeDynamicColor;
							size: QrCodeDynamicSize;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							label: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
				| {
						__typename: "QRCodeDynamicScrollBoxComponent";
						key: string;
						components: Array<{
							__typename?: "QRCodeDynamicTextComponent";
							display: {
								__typename?: "QRCodeDynamicTextComponentDisplay";
								variant: QrCodeDynamicTextComponentVariant;
								color: QrCodeDynamicColor;
								textAlign: QrCodeDynamicTextAlign;
								margin?: {
									__typename?: "QRCodeDynamicMargin";
									top?: number | null;
									right?: number | null;
									bottom?: number | null;
									left?: number | null;
								} | null;
								text: { __typename?: "Translatable"; de: string; en: string };
							};
						}>;
						display: {
							__typename?: "QRCodeDynamicScrollBoxComponentDisplay";
							background?: string | null;
							backgroundImage?: string | null;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
						};
				  }
				| {
						__typename: "QRCodeDynamicSelectComponent";
						selectState: {
							__typename?: "QRCodeDynamicSelectComponentState";
							key: string;
							next?: string | null;
							required: boolean;
							defaultValue?: string | null;
							values: Array<string>;
							valueType: InputValueType;
						};
						selectDisplay: {
							__typename?: "QRCodeDynamicSelectComponentDisplay";
							type: QrCodeDynamicSelectComponentType;
							color: QrCodeDynamicColor;
							labels: Array<{ __typename?: "Translatable"; de: string; en: string }>;
						};
				  }
				| {
						__typename: "QRCodeDynamicTextBoxComponent";
						display: {
							__typename?: "QRCodeDynamicTextBoxComponentDisplay";
							variant: QrCodeDynamicTextComponentVariant;
							color: QrCodeDynamicColor;
							background?: string | null;
							backgroundImage?: string | null;
							textAlign: QrCodeDynamicTextAlign;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							text: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
				| {
						__typename: "QRCodeDynamicTextComponent";
						display: {
							__typename?: "QRCodeDynamicTextComponentDisplay";
							variant: QrCodeDynamicTextComponentVariant;
							color: QrCodeDynamicColor;
							textAlign: QrCodeDynamicTextAlign;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							text: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
				| {
						__typename: "QRCodeDynamicTextfieldComponent";
						state: { __typename?: "QRCodeDynamicTextfieldComponentState"; key: string };
						display: {
							__typename?: "QRCodeDynamicTextfieldComponentDisplay";
							multiline?: boolean | null;
							rows?: number | null;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							label: { __typename?: "Translatable"; de: string; en: string };
						};
				  }
			>;
		}>;
	};
};

export type QrCodeDynamicFlexBoxComponentFragment = {
	__typename?: "QRCodeDynamicFlexBoxComponent";
	display: {
		__typename: "QRCodeDynamicFlexBoxComponentDisplay";
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		children: Array<
			| {
					__typename: "QRCodeDynamicBinaryChoiceComponent";
					state: {
						__typename?: "QRCodeDynamicBinaryChoiceComponentState";
						key: string;
						next?: string | null;
						values: {
							__typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
							option1: string;
							option2: string;
						};
					};
					display: {
						__typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
						color: QrCodeDynamicColor;
						size: QrCodeDynamicSize;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						labels: {
							__typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
							option1: { __typename?: "Translatable"; de: string; en: string };
							option2: { __typename?: "Translatable"; de: string; en: string };
						};
					};
			  }
			| {
					__typename: "QRCodeDynamicButtonComponent";
					state:
						| {
								__typename: "QRCodeDynamicButtonComponentSelectState";
								key: string;
								value: string;
								next?: string | null;
						  }
						| {
								__typename: "QRCodeDynamicButtonComponentSubmitState";
								key: string;
								next?: string | null;
						  };
					display: {
						__typename?: "QRCodeDynamicButtonComponentDisplay";
						color: QrCodeDynamicColor;
						size: QrCodeDynamicSize;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						label: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
			| { __typename: "QRCodeDynamicFlexBoxComponent" }
			| {
					__typename: "QRCodeDynamicImageComponent";
					display: {
						__typename?: "QRCodeDynamicImageComponentDisplay";
						src: string;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
					};
			  }
			| {
					__typename: "QRCodeDynamicInfoComponent";
					display: {
						__typename?: "QRCodeDynamicInfoComponentDisplay";
						color: QrCodeDynamicColor;
						textAlign: QrCodeDynamicTextAlign;
						titleVariant: QrCodeDynamicTextComponentVariant;
						textVariant: QrCodeDynamicTextComponentVariant;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						title: { __typename?: "Translatable"; de: string; en: string };
						text: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
			| {
					__typename: "QRCodeDynamicLinkComponent";
					state: {
						__typename?: "QRCodeDynamicLinkComponentState";
						external: boolean;
						path: string;
					};
					display: {
						__typename?: "QRCodeDynamicLinkComponentDisplay";
						color: QrCodeDynamicColor;
						size: QrCodeDynamicSize;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						label: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
			| {
					__typename: "QRCodeDynamicScrollBoxComponent";
					key: string;
					components: Array<{
						__typename?: "QRCodeDynamicTextComponent";
						display: {
							__typename?: "QRCodeDynamicTextComponentDisplay";
							variant: QrCodeDynamicTextComponentVariant;
							color: QrCodeDynamicColor;
							textAlign: QrCodeDynamicTextAlign;
							margin?: {
								__typename?: "QRCodeDynamicMargin";
								top?: number | null;
								right?: number | null;
								bottom?: number | null;
								left?: number | null;
							} | null;
							text: { __typename?: "Translatable"; de: string; en: string };
						};
					}>;
					display: {
						__typename?: "QRCodeDynamicScrollBoxComponentDisplay";
						background?: string | null;
						backgroundImage?: string | null;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
					};
			  }
			| {
					__typename: "QRCodeDynamicSelectComponent";
					selectState: {
						__typename?: "QRCodeDynamicSelectComponentState";
						key: string;
						next?: string | null;
						required: boolean;
						defaultValue?: string | null;
						values: Array<string>;
						valueType: InputValueType;
					};
					selectDisplay: {
						__typename?: "QRCodeDynamicSelectComponentDisplay";
						type: QrCodeDynamicSelectComponentType;
						color: QrCodeDynamicColor;
						labels: Array<{ __typename?: "Translatable"; de: string; en: string }>;
					};
			  }
			| {
					__typename: "QRCodeDynamicTextBoxComponent";
					display: {
						__typename?: "QRCodeDynamicTextBoxComponentDisplay";
						variant: QrCodeDynamicTextComponentVariant;
						color: QrCodeDynamicColor;
						background?: string | null;
						backgroundImage?: string | null;
						textAlign: QrCodeDynamicTextAlign;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						text: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
			| {
					__typename: "QRCodeDynamicTextComponent";
					display: {
						__typename?: "QRCodeDynamicTextComponentDisplay";
						variant: QrCodeDynamicTextComponentVariant;
						color: QrCodeDynamicColor;
						textAlign: QrCodeDynamicTextAlign;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						text: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
			| {
					__typename: "QRCodeDynamicTextfieldComponent";
					state: { __typename?: "QRCodeDynamicTextfieldComponentState"; key: string };
					display: {
						__typename?: "QRCodeDynamicTextfieldComponentDisplay";
						multiline?: boolean | null;
						rows?: number | null;
						margin?: {
							__typename?: "QRCodeDynamicMargin";
							top?: number | null;
							right?: number | null;
							bottom?: number | null;
							left?: number | null;
						} | null;
						label: { __typename?: "Translatable"; de: string; en: string };
					};
			  }
		>;
	};
};

export type QrCodeDynamicImageComponentFragment = {
	__typename?: "QRCodeDynamicImageComponent";
	display: {
		__typename?: "QRCodeDynamicImageComponentDisplay";
		src: string;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
	};
};

export type QrCodeDynamicTextfieldComponentFragment = {
	__typename?: "QRCodeDynamicTextfieldComponent";
	state: { __typename?: "QRCodeDynamicTextfieldComponentState"; key: string };
	display: {
		__typename?: "QRCodeDynamicTextfieldComponentDisplay";
		multiline?: boolean | null;
		rows?: number | null;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		label: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeDynamicBinaryChoiceComponentFragment = {
	__typename?: "QRCodeDynamicBinaryChoiceComponent";
	state: {
		__typename?: "QRCodeDynamicBinaryChoiceComponentState";
		key: string;
		next?: string | null;
		values: {
			__typename?: "QRCodeDynamicBinaryChoiceComponentStateValues";
			option1: string;
			option2: string;
		};
	};
	display: {
		__typename?: "QRCodeDynamicBinaryChoiceComponentDisplay";
		color: QrCodeDynamicColor;
		size: QrCodeDynamicSize;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		labels: {
			__typename?: "QRCodeDynamicBinaryChoiceComponentDisplayLabels";
			option1: { __typename?: "Translatable"; de: string; en: string };
			option2: { __typename?: "Translatable"; de: string; en: string };
		};
	};
};

export type QrCodeDynamicSelectComponentFragment = {
	__typename?: "QRCodeDynamicSelectComponent";
	selectState: {
		__typename?: "QRCodeDynamicSelectComponentState";
		key: string;
		next?: string | null;
		required: boolean;
		defaultValue?: string | null;
		values: Array<string>;
		valueType: InputValueType;
	};
	selectDisplay: {
		__typename?: "QRCodeDynamicSelectComponentDisplay";
		type: QrCodeDynamicSelectComponentType;
		color: QrCodeDynamicColor;
		labels: Array<{ __typename?: "Translatable"; de: string; en: string }>;
	};
};

export type QrCodeDynamicButtonComponentFragment = {
	__typename?: "QRCodeDynamicButtonComponent";
	state:
		| {
				__typename: "QRCodeDynamicButtonComponentSelectState";
				key: string;
				value: string;
				next?: string | null;
		  }
		| { __typename: "QRCodeDynamicButtonComponentSubmitState"; key: string; next?: string | null };
	display: {
		__typename?: "QRCodeDynamicButtonComponentDisplay";
		color: QrCodeDynamicColor;
		size: QrCodeDynamicSize;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		label: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeDynamicLinkComponentFragment = {
	__typename?: "QRCodeDynamicLinkComponent";
	state: { __typename?: "QRCodeDynamicLinkComponentState"; external: boolean; path: string };
	display: {
		__typename?: "QRCodeDynamicLinkComponentDisplay";
		color: QrCodeDynamicColor;
		size: QrCodeDynamicSize;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		label: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeDynamicMarginFragment = {
	__typename?: "QRCodeDynamicMargin";
	top?: number | null;
	right?: number | null;
	bottom?: number | null;
	left?: number | null;
};

export type QrCodeDynamicTextComponentFragment = {
	__typename?: "QRCodeDynamicTextComponent";
	display: {
		__typename?: "QRCodeDynamicTextComponentDisplay";
		variant: QrCodeDynamicTextComponentVariant;
		color: QrCodeDynamicColor;
		textAlign: QrCodeDynamicTextAlign;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		text: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeDynamicTextBoxComponentFragment = {
	__typename?: "QRCodeDynamicTextBoxComponent";
	display: {
		__typename?: "QRCodeDynamicTextBoxComponentDisplay";
		variant: QrCodeDynamicTextComponentVariant;
		color: QrCodeDynamicColor;
		background?: string | null;
		backgroundImage?: string | null;
		textAlign: QrCodeDynamicTextAlign;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		text: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeDynamicScrollBoxComponentFragment = {
	__typename?: "QRCodeDynamicScrollBoxComponent";
	key: string;
	components: Array<{
		__typename?: "QRCodeDynamicTextComponent";
		display: {
			__typename?: "QRCodeDynamicTextComponentDisplay";
			variant: QrCodeDynamicTextComponentVariant;
			color: QrCodeDynamicColor;
			textAlign: QrCodeDynamicTextAlign;
			margin?: {
				__typename?: "QRCodeDynamicMargin";
				top?: number | null;
				right?: number | null;
				bottom?: number | null;
				left?: number | null;
			} | null;
			text: { __typename?: "Translatable"; de: string; en: string };
		};
	}>;
	display: {
		__typename?: "QRCodeDynamicScrollBoxComponentDisplay";
		background?: string | null;
		backgroundImage?: string | null;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
	};
};

export type QrCodeDynamicInfoComponentFragment = {
	__typename?: "QRCodeDynamicInfoComponent";
	display: {
		__typename?: "QRCodeDynamicInfoComponentDisplay";
		color: QrCodeDynamicColor;
		textAlign: QrCodeDynamicTextAlign;
		titleVariant: QrCodeDynamicTextComponentVariant;
		textVariant: QrCodeDynamicTextComponentVariant;
		margin?: {
			__typename?: "QRCodeDynamicMargin";
			top?: number | null;
			right?: number | null;
			bottom?: number | null;
			left?: number | null;
		} | null;
		title: { __typename?: "Translatable"; de: string; en: string };
		text: { __typename?: "Translatable"; de: string; en: string };
	};
};

export type QrCodeTypeQueryVariables = Exact<{
	id: Scalars["String"]["input"];
	language: Scalars["String"]["input"];
}>;

export type QrCodeTypeQuery = {
	__typename?: "Query";
	qrCodeType:
		| { __typename: "ErrorReturn"; message: string }
		| {
				__typename: "QRCodeDynamic";
				id: string;
				qrCode: {
					__typename: "QRCode";
					id: string;
					venue?: {
						__typename?: "Venue";
						id: string;
						name: string;
						isActive: boolean;
						contactNumber?: string | null;
						active: {
							__typename?: "VenueActive";
							feedback?: {
								__typename?: "VenueActiveFeedback";
								link: string;
								button: { __typename?: "Translatable"; de: string; en: string };
								text: { __typename?: "Translatable"; de: string; en: string };
							} | null;
						};
						settings: {
							__typename?: "VenueSettings";
							hasChat: boolean;
							showQuestions: boolean;
							enablePositionSharing: boolean;
							requirePosition: boolean;
							requirePositionForVenue: boolean;
							requirePositionForQRCodes: boolean;
							inactivityTimeout: number;
							situations: Array<Situation>;
						};
						theme?: {
							__typename: "VenueTheme";
							id: string;
							texts: {
								__typename?: "Translations";
								personStepHeadline?: string | null;
								personStepSubtitle?: string | null;
								situationStepSubtitle?: string | null;
								waitingHeadline?: string | null;
								waitingHeadlineAccepted?: string | null;
								waitingMessage?: string | null;
								waitingMessageAccepted?: string | null;
								automaticFirstMessage?: string | null;
								automaticUnavailableMessage?: string | null;
							};
							colors: {
								__typename?: "VenueThemeColors";
								primaryColor: string;
								secondaryColor: string;
								background: string;
							};
						} | null;
					} | null;
					position?: {
						__typename: "Position";
						id: string;
						name?: string | null;
						location: {
							__typename: "Location";
							id: string;
							name: string;
							venue: {
								__typename?: "Venue";
								id: string;
								name: string;
								isActive: boolean;
								contactNumber?: string | null;
								active: {
									__typename?: "VenueActive";
									feedback?: {
										__typename?: "VenueActiveFeedback";
										link: string;
										button: { __typename?: "Translatable"; de: string; en: string };
										text: { __typename?: "Translatable"; de: string; en: string };
									} | null;
								};
								settings: {
									__typename?: "VenueSettings";
									hasChat: boolean;
									showQuestions: boolean;
									enablePositionSharing: boolean;
									requirePosition: boolean;
									requirePositionForVenue: boolean;
									requirePositionForQRCodes: boolean;
									inactivityTimeout: number;
									situations: Array<Situation>;
								};
								theme?: {
									__typename: "VenueTheme";
									id: string;
									texts: {
										__typename?: "Translations";
										personStepHeadline?: string | null;
										personStepSubtitle?: string | null;
										situationStepSubtitle?: string | null;
										waitingHeadline?: string | null;
										waitingHeadlineAccepted?: string | null;
										waitingMessage?: string | null;
										waitingMessageAccepted?: string | null;
										automaticFirstMessage?: string | null;
										automaticUnavailableMessage?: string | null;
									};
									colors: {
										__typename?: "VenueThemeColors";
										primaryColor: string;
										secondaryColor: string;
										background: string;
									};
								} | null;
							};
						};
					} | null;
				};
		  }
		| {
				__typename: "QRCodeHelp";
				id: string;
				qrCode: {
					__typename: "QRCode";
					id: string;
					venue?: {
						__typename?: "Venue";
						id: string;
						name: string;
						isActive: boolean;
						contactNumber?: string | null;
						active: {
							__typename?: "VenueActive";
							feedback?: {
								__typename?: "VenueActiveFeedback";
								link: string;
								button: { __typename?: "Translatable"; de: string; en: string };
								text: { __typename?: "Translatable"; de: string; en: string };
							} | null;
						};
						settings: {
							__typename?: "VenueSettings";
							hasChat: boolean;
							showQuestions: boolean;
							enablePositionSharing: boolean;
							requirePosition: boolean;
							requirePositionForVenue: boolean;
							requirePositionForQRCodes: boolean;
							inactivityTimeout: number;
							situations: Array<Situation>;
						};
						theme?: {
							__typename: "VenueTheme";
							id: string;
							texts: {
								__typename?: "Translations";
								personStepHeadline?: string | null;
								personStepSubtitle?: string | null;
								situationStepSubtitle?: string | null;
								waitingHeadline?: string | null;
								waitingHeadlineAccepted?: string | null;
								waitingMessage?: string | null;
								waitingMessageAccepted?: string | null;
								automaticFirstMessage?: string | null;
								automaticUnavailableMessage?: string | null;
							};
							colors: {
								__typename?: "VenueThemeColors";
								primaryColor: string;
								secondaryColor: string;
								background: string;
							};
						} | null;
					} | null;
					position?: {
						__typename: "Position";
						id: string;
						name?: string | null;
						location: {
							__typename: "Location";
							id: string;
							name: string;
							venue: {
								__typename?: "Venue";
								id: string;
								name: string;
								isActive: boolean;
								contactNumber?: string | null;
								active: {
									__typename?: "VenueActive";
									feedback?: {
										__typename?: "VenueActiveFeedback";
										link: string;
										button: { __typename?: "Translatable"; de: string; en: string };
										text: { __typename?: "Translatable"; de: string; en: string };
									} | null;
								};
								settings: {
									__typename?: "VenueSettings";
									hasChat: boolean;
									showQuestions: boolean;
									enablePositionSharing: boolean;
									requirePosition: boolean;
									requirePositionForVenue: boolean;
									requirePositionForQRCodes: boolean;
									inactivityTimeout: number;
									situations: Array<Situation>;
								};
								theme?: {
									__typename: "VenueTheme";
									id: string;
									texts: {
										__typename?: "Translations";
										personStepHeadline?: string | null;
										personStepSubtitle?: string | null;
										situationStepSubtitle?: string | null;
										waitingHeadline?: string | null;
										waitingHeadlineAccepted?: string | null;
										waitingMessage?: string | null;
										waitingMessageAccepted?: string | null;
										automaticFirstMessage?: string | null;
										automaticUnavailableMessage?: string | null;
									};
									colors: {
										__typename?: "VenueThemeColors";
										primaryColor: string;
										secondaryColor: string;
										background: string;
									};
								} | null;
							};
						};
					} | null;
				};
		  }
		| {
				__typename: "QRCodeInternalChat";
				id: string;
				qrCode: {
					__typename: "QRCode";
					id: string;
					venue?: {
						__typename?: "Venue";
						id: string;
						name: string;
						isActive: boolean;
						contactNumber?: string | null;
						active: {
							__typename?: "VenueActive";
							feedback?: {
								__typename?: "VenueActiveFeedback";
								link: string;
								button: { __typename?: "Translatable"; de: string; en: string };
								text: { __typename?: "Translatable"; de: string; en: string };
							} | null;
						};
						settings: {
							__typename?: "VenueSettings";
							hasChat: boolean;
							showQuestions: boolean;
							enablePositionSharing: boolean;
							requirePosition: boolean;
							requirePositionForVenue: boolean;
							requirePositionForQRCodes: boolean;
							inactivityTimeout: number;
							situations: Array<Situation>;
						};
						theme?: {
							__typename: "VenueTheme";
							id: string;
							texts: {
								__typename?: "Translations";
								personStepHeadline?: string | null;
								personStepSubtitle?: string | null;
								situationStepSubtitle?: string | null;
								waitingHeadline?: string | null;
								waitingHeadlineAccepted?: string | null;
								waitingMessage?: string | null;
								waitingMessageAccepted?: string | null;
								automaticFirstMessage?: string | null;
								automaticUnavailableMessage?: string | null;
							};
							colors: {
								__typename?: "VenueThemeColors";
								primaryColor: string;
								secondaryColor: string;
								background: string;
							};
						} | null;
					} | null;
					position?: {
						__typename: "Position";
						id: string;
						name?: string | null;
						location: {
							__typename: "Location";
							id: string;
							name: string;
							venue: {
								__typename?: "Venue";
								id: string;
								name: string;
								isActive: boolean;
								contactNumber?: string | null;
								active: {
									__typename?: "VenueActive";
									feedback?: {
										__typename?: "VenueActiveFeedback";
										link: string;
										button: { __typename?: "Translatable"; de: string; en: string };
										text: { __typename?: "Translatable"; de: string; en: string };
									} | null;
								};
								settings: {
									__typename?: "VenueSettings";
									hasChat: boolean;
									showQuestions: boolean;
									enablePositionSharing: boolean;
									requirePosition: boolean;
									requirePositionForVenue: boolean;
									requirePositionForQRCodes: boolean;
									inactivityTimeout: number;
									situations: Array<Situation>;
								};
								theme?: {
									__typename: "VenueTheme";
									id: string;
									texts: {
										__typename?: "Translations";
										personStepHeadline?: string | null;
										personStepSubtitle?: string | null;
										situationStepSubtitle?: string | null;
										waitingHeadline?: string | null;
										waitingHeadlineAccepted?: string | null;
										waitingMessage?: string | null;
										waitingMessageAccepted?: string | null;
										automaticFirstMessage?: string | null;
										automaticUnavailableMessage?: string | null;
									};
									colors: {
										__typename?: "VenueThemeColors";
										primaryColor: string;
										secondaryColor: string;
										background: string;
									};
								} | null;
							};
						};
					} | null;
				};
		  };
};

export type QrCodeFragment = {
	__typename: "QRCode";
	id: string;
	venue?: {
		__typename?: "Venue";
		id: string;
		name: string;
		isActive: boolean;
		contactNumber?: string | null;
		active: {
			__typename?: "VenueActive";
			feedback?: {
				__typename?: "VenueActiveFeedback";
				link: string;
				button: { __typename?: "Translatable"; de: string; en: string };
				text: { __typename?: "Translatable"; de: string; en: string };
			} | null;
		};
		settings: {
			__typename?: "VenueSettings";
			hasChat: boolean;
			showQuestions: boolean;
			enablePositionSharing: boolean;
			requirePosition: boolean;
			requirePositionForVenue: boolean;
			requirePositionForQRCodes: boolean;
			inactivityTimeout: number;
			situations: Array<Situation>;
		};
		theme?: {
			__typename: "VenueTheme";
			id: string;
			texts: {
				__typename?: "Translations";
				personStepHeadline?: string | null;
				personStepSubtitle?: string | null;
				situationStepSubtitle?: string | null;
				waitingHeadline?: string | null;
				waitingHeadlineAccepted?: string | null;
				waitingMessage?: string | null;
				waitingMessageAccepted?: string | null;
				automaticFirstMessage?: string | null;
				automaticUnavailableMessage?: string | null;
			};
			colors: {
				__typename?: "VenueThemeColors";
				primaryColor: string;
				secondaryColor: string;
				background: string;
			};
		} | null;
	} | null;
	position?: {
		__typename: "Position";
		id: string;
		name?: string | null;
		location: {
			__typename: "Location";
			id: string;
			name: string;
			venue: {
				__typename?: "Venue";
				id: string;
				name: string;
				isActive: boolean;
				contactNumber?: string | null;
				active: {
					__typename?: "VenueActive";
					feedback?: {
						__typename?: "VenueActiveFeedback";
						link: string;
						button: { __typename?: "Translatable"; de: string; en: string };
						text: { __typename?: "Translatable"; de: string; en: string };
					} | null;
				};
				settings: {
					__typename?: "VenueSettings";
					hasChat: boolean;
					showQuestions: boolean;
					enablePositionSharing: boolean;
					requirePosition: boolean;
					requirePositionForVenue: boolean;
					requirePositionForQRCodes: boolean;
					inactivityTimeout: number;
					situations: Array<Situation>;
				};
				theme?: {
					__typename: "VenueTheme";
					id: string;
					texts: {
						__typename?: "Translations";
						personStepHeadline?: string | null;
						personStepSubtitle?: string | null;
						situationStepSubtitle?: string | null;
						waitingHeadline?: string | null;
						waitingHeadlineAccepted?: string | null;
						waitingMessage?: string | null;
						waitingMessageAccepted?: string | null;
						automaticFirstMessage?: string | null;
						automaticUnavailableMessage?: string | null;
					};
					colors: {
						__typename?: "VenueThemeColors";
						primaryColor: string;
						secondaryColor: string;
						background: string;
					};
				} | null;
			};
		};
	} | null;
};

export type VenueFragment = {
	__typename?: "Venue";
	id: string;
	name: string;
	isActive: boolean;
	contactNumber?: string | null;
	active: {
		__typename?: "VenueActive";
		feedback?: {
			__typename?: "VenueActiveFeedback";
			link: string;
			button: { __typename?: "Translatable"; de: string; en: string };
			text: { __typename?: "Translatable"; de: string; en: string };
		} | null;
	};
	settings: {
		__typename?: "VenueSettings";
		hasChat: boolean;
		showQuestions: boolean;
		enablePositionSharing: boolean;
		requirePosition: boolean;
		requirePositionForVenue: boolean;
		requirePositionForQRCodes: boolean;
		inactivityTimeout: number;
		situations: Array<Situation>;
	};
	theme?: {
		__typename: "VenueTheme";
		id: string;
		texts: {
			__typename?: "Translations";
			personStepHeadline?: string | null;
			personStepSubtitle?: string | null;
			situationStepSubtitle?: string | null;
			waitingHeadline?: string | null;
			waitingHeadlineAccepted?: string | null;
			waitingMessage?: string | null;
			waitingMessageAccepted?: string | null;
			automaticFirstMessage?: string | null;
			automaticUnavailableMessage?: string | null;
		};
		colors: {
			__typename?: "VenueThemeColors";
			primaryColor: string;
			secondaryColor: string;
			background: string;
		};
	} | null;
};

export type SubmitDynamicRequestMutationVariables = Exact<{
	qrCodeId: Scalars["String"]["input"];
	coordinates: CoordinatesInput;
	language: Scalars["String"]["input"];
	input: Array<SubmitDynamicRequestInput> | SubmitDynamicRequestInput;
}>;

export type SubmitDynamicRequestMutation = {
	__typename?: "Mutation";
	submitDynamicRequest: string;
};

export type TranslatableFragment = { __typename?: "Translatable"; de: string; en: string };

export type NearestVenueForTagQueryVariables = Exact<{
	coordinates: LatLngRequiredInput;
	tag: Scalars["String"]["input"];
}>;

export type NearestVenueForTagQuery = { __typename?: "Query"; nearestVenueForTag?: string | null };

export type GetVenueBySlugQueryVariables = Exact<{
	slug: Scalars["String"]["input"];
}>;

export type GetVenueBySlugQuery = {
	__typename?: "Query";
	venueBySlug?: { __typename: "Venue"; id: string } | null;
};

export type SubmitVenueHelpRequestMutationVariables = Exact<{
	input: SubmitVenueHelpRequestInput;
}>;

export type SubmitVenueHelpRequestMutation = {
	__typename?: "Mutation";
	submitVenueHelpRequest: string;
};

export type GetChatPreviewQueryVariables = Exact<{
	venueId: Scalars["String"]["input"];
	language: Scalars["String"]["input"];
}>;

export type GetChatPreviewQuery = {
	__typename?: "Query";
	venue: { __typename: "Venue"; id: string; name: string };
	getVenueTheme?: {
		__typename: "VenueTheme";
		id: string;
		texts: {
			__typename?: "Translations";
			automaticFirstMessage?: string | null;
			automaticUnavailableMessage?: string | null;
		};
	} | null;
};

export type GetChatQueryVariables = Exact<{
	uuid: Scalars["String"]["input"];
	venueId: Scalars["String"]["input"];
}>;

export type GetChatQuery = {
	__typename?: "Query";
	chat: {
		__typename: "Chat";
		id: string;
		messages: Array<{
			__typename: "ChatMessage";
			id: string;
			date: string;
			sender?: string | null;
			text: string;
			type: MessageType;
		}>;
	};
};

export type NewChatMessageSubscriptionVariables = Exact<{
	uuid: Scalars["String"]["input"];
}>;

export type NewChatMessageSubscription = {
	__typename?: "Subscription";
	newChatMessage: {
		__typename: "ChatMessage";
		id: string;
		date: string;
		sender?: string | null;
		text: string;
		type: MessageType;
	};
};

export type GetVenueQueryVariables = Exact<{
	venueId: Scalars["String"]["input"];
	language: Scalars["String"]["input"];
}>;

export type GetVenueQuery = {
	__typename?: "Query";
	venue: {
		__typename: "Venue";
		id: string;
		name: string;
		image: string;
		isActive: boolean;
		settings: {
			__typename?: "VenueSettings";
			hasChat: boolean;
			showQuestions: boolean;
			enablePositionSharing: boolean;
			requirePosition: boolean;
			requirePositionForVenue: boolean;
			requirePositionForQRCodes: boolean;
			inactivityTimeout: number;
			situations: Array<Situation>;
		};
		active: {
			__typename?: "VenueActive";
			feedback?: {
				__typename?: "VenueActiveFeedback";
				link: string;
				text: { __typename?: "Translatable"; de: string; en: string };
				button: { __typename?: "Translatable"; de: string; en: string };
			} | null;
		};
		theme?: {
			__typename: "VenueTheme";
			id: string;
			texts: {
				__typename?: "Translations";
				personStepHeadline?: string | null;
				personStepSubtitle?: string | null;
				situationStepSubtitle?: string | null;
				waitingHeadline?: string | null;
				waitingHeadlineAccepted?: string | null;
				waitingMessage?: string | null;
				waitingMessageAccepted?: string | null;
				automaticFirstMessage?: string | null;
				automaticUnavailableMessage?: string | null;
			};
			images?: {
				__typename?: "VenueThemeImages";
				logo?: string | null;
				background?: string | null;
			} | null;
			colors: {
				__typename?: "VenueThemeColors";
				primaryColor: string;
				secondaryColor: string;
				background: string;
			};
		} | null;
	};
};

export type VenueThemeTextFragment = {
	__typename?: "Translations";
	personStepHeadline?: string | null;
	personStepSubtitle?: string | null;
	situationStepSubtitle?: string | null;
	waitingHeadline?: string | null;
	waitingHeadlineAccepted?: string | null;
	waitingMessage?: string | null;
	waitingMessageAccepted?: string | null;
	automaticFirstMessage?: string | null;
	automaticUnavailableMessage?: string | null;
};

export type VenueThemeColorsFragment = {
	__typename?: "VenueThemeColors";
	primaryColor: string;
	secondaryColor: string;
	background: string;
};

export const QrCodeDynamicMarginFragmentDoc = gql`
	fragment QRCodeDynamicMargin on QRCodeDynamicMargin {
		top
		right
		bottom
		left
	}
`;
export const TranslatableFragmentDoc = gql`
	fragment Translatable on Translatable {
		de
		en
	}
`;
export const QrCodeDynamicTextComponentFragmentDoc = gql`
	fragment QRCodeDynamicTextComponent on QRCodeDynamicTextComponent {
		display {
			variant
			color
			textAlign
			margin {
				...QRCodeDynamicMargin
			}
			text {
				...Translatable
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicScrollBoxComponentFragmentDoc = gql`
	fragment QRCodeDynamicScrollBoxComponent on QRCodeDynamicScrollBoxComponent {
		key
		components {
			...QRCodeDynamicTextComponent
		}
		display {
			background
			backgroundImage
			margin {
				...QRCodeDynamicMargin
			}
		}
	}
	${QrCodeDynamicTextComponentFragmentDoc}
	${QrCodeDynamicMarginFragmentDoc}
`;
export const QrCodeDynamicImageComponentFragmentDoc = gql`
	fragment QRCodeDynamicImageComponent on QRCodeDynamicImageComponent {
		display {
			src
			margin {
				...QRCodeDynamicMargin
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
`;
export const QrCodeDynamicTextBoxComponentFragmentDoc = gql`
	fragment QRCodeDynamicTextBoxComponent on QRCodeDynamicTextBoxComponent {
		display {
			variant
			color
			background
			backgroundImage
			textAlign
			margin {
				...QRCodeDynamicMargin
			}
			text {
				...Translatable
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicInfoComponentFragmentDoc = gql`
	fragment QRCodeDynamicInfoComponent on QRCodeDynamicInfoComponent {
		display {
			color
			textAlign
			margin {
				...QRCodeDynamicMargin
			}
			title {
				...Translatable
			}
			titleVariant
			text {
				...Translatable
			}
			textVariant
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicLinkComponentFragmentDoc = gql`
	fragment QRCodeDynamicLinkComponent on QRCodeDynamicLinkComponent {
		state {
			external
			path
		}
		display {
			color
			size
			margin {
				...QRCodeDynamicMargin
			}
			label {
				...Translatable
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicButtonComponentFragmentDoc = gql`
	fragment QRCodeDynamicButtonComponent on QRCodeDynamicButtonComponent {
		state {
			... on QRCodeDynamicButtonComponentSubmitState {
				__typename
				key
				next
			}
			... on QRCodeDynamicButtonComponentSelectState {
				__typename
				key
				value
				next
			}
		}
		display {
			color
			size
			margin {
				...QRCodeDynamicMargin
			}
			label {
				...Translatable
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicTextfieldComponentFragmentDoc = gql`
	fragment QRCodeDynamicTextfieldComponent on QRCodeDynamicTextfieldComponent {
		state {
			key
		}
		display {
			multiline
			rows
			margin {
				...QRCodeDynamicMargin
			}
			label {
				...Translatable
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicBinaryChoiceComponentFragmentDoc = gql`
	fragment QRCodeDynamicBinaryChoiceComponent on QRCodeDynamicBinaryChoiceComponent {
		state {
			key
			next
			values {
				option1
				option2
			}
		}
		display {
			color
			size
			margin {
				...QRCodeDynamicMargin
			}
			labels {
				option1 {
					...Translatable
				}
				option2 {
					...Translatable
				}
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicSelectComponentFragmentDoc = gql`
	fragment QRCodeDynamicSelectComponent on QRCodeDynamicSelectComponent {
		selectState: state {
			key
			next
			required
			defaultValue
			values
			valueType
		}
		selectDisplay: display {
			type
			color
			labels {
				...Translatable
			}
		}
	}
	${TranslatableFragmentDoc}
`;
export const QrCodeDynamicFlexBoxComponentFragmentDoc = gql`
	fragment QRCodeDynamicFlexBoxComponent on QRCodeDynamicFlexBoxComponent {
		display {
			__typename
			margin {
				...QRCodeDynamicMargin
			}
			children {
				__typename
				... on QRCodeDynamicScrollBoxComponent {
					...QRCodeDynamicScrollBoxComponent
				}
				... on QRCodeDynamicImageComponent {
					...QRCodeDynamicImageComponent
				}
				... on QRCodeDynamicTextComponent {
					...QRCodeDynamicTextComponent
				}
				... on QRCodeDynamicTextBoxComponent {
					...QRCodeDynamicTextBoxComponent
				}
				... on QRCodeDynamicInfoComponent {
					...QRCodeDynamicInfoComponent
				}
				... on QRCodeDynamicLinkComponent {
					...QRCodeDynamicLinkComponent
				}
				... on QRCodeDynamicButtonComponent {
					...QRCodeDynamicButtonComponent
				}
				... on QRCodeDynamicTextfieldComponent {
					...QRCodeDynamicTextfieldComponent
				}
				... on QRCodeDynamicBinaryChoiceComponent {
					...QRCodeDynamicBinaryChoiceComponent
				}
				... on QRCodeDynamicSelectComponent {
					...QRCodeDynamicSelectComponent
				}
			}
		}
	}
	${QrCodeDynamicMarginFragmentDoc}
	${QrCodeDynamicScrollBoxComponentFragmentDoc}
	${QrCodeDynamicImageComponentFragmentDoc}
	${QrCodeDynamicTextComponentFragmentDoc}
	${QrCodeDynamicTextBoxComponentFragmentDoc}
	${QrCodeDynamicInfoComponentFragmentDoc}
	${QrCodeDynamicLinkComponentFragmentDoc}
	${QrCodeDynamicButtonComponentFragmentDoc}
	${QrCodeDynamicTextfieldComponentFragmentDoc}
	${QrCodeDynamicBinaryChoiceComponentFragmentDoc}
	${QrCodeDynamicSelectComponentFragmentDoc}
`;
export const VenueThemeTextFragmentDoc = gql`
	fragment VenueThemeText on Translations {
		personStepHeadline
		personStepSubtitle
		situationStepSubtitle
		waitingHeadline
		waitingHeadlineAccepted
		waitingMessage
		waitingMessageAccepted
		automaticFirstMessage
		automaticUnavailableMessage
	}
`;
export const VenueThemeColorsFragmentDoc = gql`
	fragment VenueThemeColors on VenueThemeColors {
		primaryColor
		secondaryColor
		background
	}
`;
export const VenueFragmentDoc = gql`
	fragment Venue on Venue {
		id
		name
		isActive
		active {
			feedback {
				button {
					...Translatable
				}
				text {
					...Translatable
				}
				link
			}
		}
		settings {
			hasChat
			showQuestions
			enablePositionSharing
			requirePosition
			requirePositionForVenue
			requirePositionForQRCodes
			inactivityTimeout
			situations
		}
		contactNumber
		theme {
			__typename
			id
			texts(language: $language) {
				...VenueThemeText
			}
			colors {
				...VenueThemeColors
			}
		}
	}
	${TranslatableFragmentDoc}
	${VenueThemeTextFragmentDoc}
	${VenueThemeColorsFragmentDoc}
`;
export const QrCodeFragmentDoc = gql`
	fragment QRCode on QRCode {
		__typename
		id
		venue {
			...Venue
		}
		position {
			__typename
			id
			name
			location {
				__typename
				id
				name
				venue {
					...Venue
				}
			}
		}
	}
	${VenueFragmentDoc}
`;
export const OnTypingStatusChangedDocument = gql`
	subscription OnTypingStatusChanged($helpRequestId: String!, $venueId: String!) {
		onTypingStatusChanged(helpRequestId: $helpRequestId, venueId: $venueId)
	}
`;

/**
 * __useOnTypingStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOnTypingStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTypingStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTypingStatusChangedSubscription({
 *   variables: {
 *      helpRequestId: // value for 'helpRequestId'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useOnTypingStatusChangedSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		OnTypingStatusChangedSubscription,
		OnTypingStatusChangedSubscriptionVariables
	> &
		({ variables: OnTypingStatusChangedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		OnTypingStatusChangedSubscription,
		OnTypingStatusChangedSubscriptionVariables
	>(OnTypingStatusChangedDocument, options);
}
export type OnTypingStatusChangedSubscriptionHookResult = ReturnType<
	typeof useOnTypingStatusChangedSubscription
>;
export type OnTypingStatusChangedSubscriptionResult =
	Apollo.SubscriptionResult<OnTypingStatusChangedSubscription>;
export const GetCurrentSecurityPositionsDocument = gql`
	query GetCurrentSecurityPositions($uuid: String!, $venueId: String!) {
		getCurrentSecurityPositions(uuid: $uuid, venueId: $venueId) {
			__typename
			... on CurrentSecurityPositionsSuccessResult {
				positions {
					lat
					lng
				}
			}
			... on ErrorReturn {
				message
				statusCode
				i18nKey
			}
		}
	}
`;

/**
 * __useGetCurrentSecurityPositionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentSecurityPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSecurityPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSecurityPositionsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetCurrentSecurityPositionsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetCurrentSecurityPositionsQuery,
		GetCurrentSecurityPositionsQueryVariables
	> &
		({ variables: GetCurrentSecurityPositionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetCurrentSecurityPositionsQuery,
		GetCurrentSecurityPositionsQueryVariables
	>(GetCurrentSecurityPositionsDocument, options);
}
export function useGetCurrentSecurityPositionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCurrentSecurityPositionsQuery,
		GetCurrentSecurityPositionsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetCurrentSecurityPositionsQuery,
		GetCurrentSecurityPositionsQueryVariables
	>(GetCurrentSecurityPositionsDocument, options);
}
export function useGetCurrentSecurityPositionsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetCurrentSecurityPositionsQuery,
				GetCurrentSecurityPositionsQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<
		GetCurrentSecurityPositionsQuery,
		GetCurrentSecurityPositionsQueryVariables
	>(GetCurrentSecurityPositionsDocument, options);
}
export type GetCurrentSecurityPositionsQueryHookResult = ReturnType<
	typeof useGetCurrentSecurityPositionsQuery
>;
export type GetCurrentSecurityPositionsLazyQueryHookResult = ReturnType<
	typeof useGetCurrentSecurityPositionsLazyQuery
>;
export type GetCurrentSecurityPositionsSuspenseQueryHookResult = ReturnType<
	typeof useGetCurrentSecurityPositionsSuspenseQuery
>;
export type GetCurrentSecurityPositionsQueryResult = Apollo.QueryResult<
	GetCurrentSecurityPositionsQuery,
	GetCurrentSecurityPositionsQueryVariables
>;
export const UpdateLastCharacterTypedAtDocument = gql`
	mutation UpdateLastCharacterTypedAt($input: UpdateTypingInput!) {
		updateLastCharacterTypedAt(input: $input)
	}
`;
export type UpdateLastCharacterTypedAtMutationFn = Apollo.MutationFunction<
	UpdateLastCharacterTypedAtMutation,
	UpdateLastCharacterTypedAtMutationVariables
>;

/**
 * __useUpdateLastCharacterTypedAtMutation__
 *
 * To run a mutation, you first call `useUpdateLastCharacterTypedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastCharacterTypedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastCharacterTypedAtMutation, { data, loading, error }] = useUpdateLastCharacterTypedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLastCharacterTypedAtMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateLastCharacterTypedAtMutation,
		UpdateLastCharacterTypedAtMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateLastCharacterTypedAtMutation,
		UpdateLastCharacterTypedAtMutationVariables
	>(UpdateLastCharacterTypedAtDocument, options);
}
export type UpdateLastCharacterTypedAtMutationHookResult = ReturnType<
	typeof useUpdateLastCharacterTypedAtMutation
>;
export type UpdateLastCharacterTypedAtMutationResult =
	Apollo.MutationResult<UpdateLastCharacterTypedAtMutation>;
export type UpdateLastCharacterTypedAtMutationOptions = Apollo.BaseMutationOptions<
	UpdateLastCharacterTypedAtMutation,
	UpdateLastCharacterTypedAtMutationVariables
>;
export const VerifyGeolocationDocument = gql`
	query VerifyGeolocation($input: VerifyGeolocationInput!) {
		verifyGeolocation(input: $input)
	}
`;

/**
 * __useVerifyGeolocationQuery__
 *
 * To run a query within a React component, call `useVerifyGeolocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyGeolocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyGeolocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyGeolocationQuery(
	baseOptions: Apollo.QueryHookOptions<VerifyGeolocationQuery, VerifyGeolocationQueryVariables> &
		({ variables: VerifyGeolocationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<VerifyGeolocationQuery, VerifyGeolocationQueryVariables>(
		VerifyGeolocationDocument,
		options,
	);
}
export function useVerifyGeolocationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		VerifyGeolocationQuery,
		VerifyGeolocationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<VerifyGeolocationQuery, VerifyGeolocationQueryVariables>(
		VerifyGeolocationDocument,
		options,
	);
}
export function useVerifyGeolocationSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<VerifyGeolocationQuery, VerifyGeolocationQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<VerifyGeolocationQuery, VerifyGeolocationQueryVariables>(
		VerifyGeolocationDocument,
		options,
	);
}
export type VerifyGeolocationQueryHookResult = ReturnType<typeof useVerifyGeolocationQuery>;
export type VerifyGeolocationLazyQueryHookResult = ReturnType<typeof useVerifyGeolocationLazyQuery>;
export type VerifyGeolocationSuspenseQueryHookResult = ReturnType<
	typeof useVerifyGeolocationSuspenseQuery
>;
export type VerifyGeolocationQueryResult = Apollo.QueryResult<
	VerifyGeolocationQuery,
	VerifyGeolocationQueryVariables
>;
export const CreateAutomaticResponseDocument = gql`
	mutation CreateAutomaticResponse($venueId: String!, $uuid: String!) {
		createAutomaticResponse(venueId: $venueId, uuid: $uuid) {
			... on ErrorReturn {
				message
			}
			... on SuccessReturn {
				success
			}
		}
	}
`;
export type CreateAutomaticResponseMutationFn = Apollo.MutationFunction<
	CreateAutomaticResponseMutation,
	CreateAutomaticResponseMutationVariables
>;

/**
 * __useCreateAutomaticResponseMutation__
 *
 * To run a mutation, you first call `useCreateAutomaticResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutomaticResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutomaticResponseMutation, { data, loading, error }] = useCreateAutomaticResponseMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCreateAutomaticResponseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateAutomaticResponseMutation,
		CreateAutomaticResponseMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateAutomaticResponseMutation,
		CreateAutomaticResponseMutationVariables
	>(CreateAutomaticResponseDocument, options);
}
export type CreateAutomaticResponseMutationHookResult = ReturnType<
	typeof useCreateAutomaticResponseMutation
>;
export type CreateAutomaticResponseMutationResult =
	Apollo.MutationResult<CreateAutomaticResponseMutation>;
export type CreateAutomaticResponseMutationOptions = Apollo.BaseMutationOptions<
	CreateAutomaticResponseMutation,
	CreateAutomaticResponseMutationVariables
>;
export const GetCurrentPositionDocument = gql`
	query GetCurrentPosition($uuid: String!) {
		getCurrentPosition(uuid: $uuid) {
			createdAt
			updatedAt
			lat
			lng
		}
	}
`;

/**
 * __useGetCurrentPositionQuery__
 *
 * To run a query within a React component, call `useGetCurrentPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPositionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCurrentPositionQuery(
	baseOptions: Apollo.QueryHookOptions<GetCurrentPositionQuery, GetCurrentPositionQueryVariables> &
		({ variables: GetCurrentPositionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetCurrentPositionQuery, GetCurrentPositionQueryVariables>(
		GetCurrentPositionDocument,
		options,
	);
}
export function useGetCurrentPositionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCurrentPositionQuery,
		GetCurrentPositionQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetCurrentPositionQuery, GetCurrentPositionQueryVariables>(
		GetCurrentPositionDocument,
		options,
	);
}
export function useGetCurrentPositionSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetCurrentPositionQuery, GetCurrentPositionQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetCurrentPositionQuery, GetCurrentPositionQueryVariables>(
		GetCurrentPositionDocument,
		options,
	);
}
export type GetCurrentPositionQueryHookResult = ReturnType<typeof useGetCurrentPositionQuery>;
export type GetCurrentPositionLazyQueryHookResult = ReturnType<
	typeof useGetCurrentPositionLazyQuery
>;
export type GetCurrentPositionSuspenseQueryHookResult = ReturnType<
	typeof useGetCurrentPositionSuspenseQuery
>;
export type GetCurrentPositionQueryResult = Apollo.QueryResult<
	GetCurrentPositionQuery,
	GetCurrentPositionQueryVariables
>;
export const GetHelpRequestBouncerResponseDocument = gql`
	subscription GetHelpRequestBouncerResponse($uuid: String) {
		getHelpRequestBouncerResponse(uuid: $uuid)
	}
`;

/**
 * __useGetHelpRequestBouncerResponseSubscription__
 *
 * To run a query within a React component, call `useGetHelpRequestBouncerResponseSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpRequestBouncerResponseSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpRequestBouncerResponseSubscription({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetHelpRequestBouncerResponseSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		GetHelpRequestBouncerResponseSubscription,
		GetHelpRequestBouncerResponseSubscriptionVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		GetHelpRequestBouncerResponseSubscription,
		GetHelpRequestBouncerResponseSubscriptionVariables
	>(GetHelpRequestBouncerResponseDocument, options);
}
export type GetHelpRequestBouncerResponseSubscriptionHookResult = ReturnType<
	typeof useGetHelpRequestBouncerResponseSubscription
>;
export type GetHelpRequestBouncerResponseSubscriptionResult =
	Apollo.SubscriptionResult<GetHelpRequestBouncerResponseSubscription>;
export const ResetCurrentPositionDocument = gql`
	mutation ResetCurrentPosition($uuid: String!, $venueId: String!) {
		resetCurrentPosition(uuid: $uuid, venueId: $venueId)
	}
`;
export type ResetCurrentPositionMutationFn = Apollo.MutationFunction<
	ResetCurrentPositionMutation,
	ResetCurrentPositionMutationVariables
>;

/**
 * __useResetCurrentPositionMutation__
 *
 * To run a mutation, you first call `useResetCurrentPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCurrentPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCurrentPositionMutation, { data, loading, error }] = useResetCurrentPositionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useResetCurrentPositionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResetCurrentPositionMutation,
		ResetCurrentPositionMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ResetCurrentPositionMutation, ResetCurrentPositionMutationVariables>(
		ResetCurrentPositionDocument,
		options,
	);
}
export type ResetCurrentPositionMutationHookResult = ReturnType<
	typeof useResetCurrentPositionMutation
>;
export type ResetCurrentPositionMutationResult =
	Apollo.MutationResult<ResetCurrentPositionMutation>;
export type ResetCurrentPositionMutationOptions = Apollo.BaseMutationOptions<
	ResetCurrentPositionMutation,
	ResetCurrentPositionMutationVariables
>;
export const SendMailDocument = gql`
	mutation SendMail($text: String!, $subject: String!) {
		sendMail(text: $text, subject: $subject) {
			success
		}
	}
`;
export type SendMailMutationFn = Apollo.MutationFunction<
	SendMailMutation,
	SendMailMutationVariables
>;

/**
 * __useSendMailMutation__
 *
 * To run a mutation, you first call `useSendMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailMutation, { data, loading, error }] = useSendMailMutation({
 *   variables: {
 *      text: // value for 'text'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useSendMailMutation(
	baseOptions?: Apollo.MutationHookOptions<SendMailMutation, SendMailMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SendMailMutation, SendMailMutationVariables>(SendMailDocument, options);
}
export type SendMailMutationHookResult = ReturnType<typeof useSendMailMutation>;
export type SendMailMutationResult = Apollo.MutationResult<SendMailMutation>;
export type SendMailMutationOptions = Apollo.BaseMutationOptions<
	SendMailMutation,
	SendMailMutationVariables
>;
export const SubmitHelpRequestDocument = gql`
	mutation SubmitHelpRequest(
		$qrCodeId: String!
		$coordinates: CoordinatesInput!
		$person: Person
		$situation: Situation
		$language: String!
	) {
		submitHelpRequest(
			qrCodeId: $qrCodeId
			coordinates: $coordinates
			person: $person
			situation: $situation
			language: $language
		)
	}
`;
export type SubmitHelpRequestMutationFn = Apollo.MutationFunction<
	SubmitHelpRequestMutation,
	SubmitHelpRequestMutationVariables
>;

/**
 * __useSubmitHelpRequestMutation__
 *
 * To run a mutation, you first call `useSubmitHelpRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitHelpRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitHelpRequestMutation, { data, loading, error }] = useSubmitHelpRequestMutation({
 *   variables: {
 *      qrCodeId: // value for 'qrCodeId'
 *      coordinates: // value for 'coordinates'
 *      person: // value for 'person'
 *      situation: // value for 'situation'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubmitHelpRequestMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubmitHelpRequestMutation,
		SubmitHelpRequestMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SubmitHelpRequestMutation, SubmitHelpRequestMutationVariables>(
		SubmitHelpRequestDocument,
		options,
	);
}
export type SubmitHelpRequestMutationHookResult = ReturnType<typeof useSubmitHelpRequestMutation>;
export type SubmitHelpRequestMutationResult = Apollo.MutationResult<SubmitHelpRequestMutation>;
export type SubmitHelpRequestMutationOptions = Apollo.BaseMutationOptions<
	SubmitHelpRequestMutation,
	SubmitHelpRequestMutationVariables
>;
export const SubmitInternalHelpRequestDocument = gql`
	mutation SubmitInternalHelpRequest($qrCodeId: String!, $severe: Boolean!, $language: String!) {
		submitInternalHelpRequest(qrCodeId: $qrCodeId, severe: $severe, language: $language)
	}
`;
export type SubmitInternalHelpRequestMutationFn = Apollo.MutationFunction<
	SubmitInternalHelpRequestMutation,
	SubmitInternalHelpRequestMutationVariables
>;

/**
 * __useSubmitInternalHelpRequestMutation__
 *
 * To run a mutation, you first call `useSubmitInternalHelpRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInternalHelpRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitInternalHelpRequestMutation, { data, loading, error }] = useSubmitInternalHelpRequestMutation({
 *   variables: {
 *      qrCodeId: // value for 'qrCodeId'
 *      severe: // value for 'severe'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubmitInternalHelpRequestMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubmitInternalHelpRequestMutation,
		SubmitInternalHelpRequestMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		SubmitInternalHelpRequestMutation,
		SubmitInternalHelpRequestMutationVariables
	>(SubmitInternalHelpRequestDocument, options);
}
export type SubmitInternalHelpRequestMutationHookResult = ReturnType<
	typeof useSubmitInternalHelpRequestMutation
>;
export type SubmitInternalHelpRequestMutationResult =
	Apollo.MutationResult<SubmitInternalHelpRequestMutation>;
export type SubmitInternalHelpRequestMutationOptions = Apollo.BaseMutationOptions<
	SubmitInternalHelpRequestMutation,
	SubmitInternalHelpRequestMutationVariables
>;
export const UpdateCurrentPositionDocument = gql`
	mutation UpdateCurrentPosition(
		$uuid: String!
		$venueId: String!
		$coordinates: CoordinatesRequiredInput!
	) {
		updateCurrentPosition(uuid: $uuid, venueId: $venueId, coordinates: $coordinates)
	}
`;
export type UpdateCurrentPositionMutationFn = Apollo.MutationFunction<
	UpdateCurrentPositionMutation,
	UpdateCurrentPositionMutationVariables
>;

/**
 * __useUpdateCurrentPositionMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentPositionMutation, { data, loading, error }] = useUpdateCurrentPositionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      venueId: // value for 'venueId'
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useUpdateCurrentPositionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateCurrentPositionMutation,
		UpdateCurrentPositionMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateCurrentPositionMutation, UpdateCurrentPositionMutationVariables>(
		UpdateCurrentPositionDocument,
		options,
	);
}
export type UpdateCurrentPositionMutationHookResult = ReturnType<
	typeof useUpdateCurrentPositionMutation
>;
export type UpdateCurrentPositionMutationResult =
	Apollo.MutationResult<UpdateCurrentPositionMutation>;
export type UpdateCurrentPositionMutationOptions = Apollo.BaseMutationOptions<
	UpdateCurrentPositionMutation,
	UpdateCurrentPositionMutationVariables
>;
export const WriteMessageDocument = gql`
	mutation WriteMessage($venueId: String!, $uuid: String!, $text: String!) {
		writeMessage(venueId: $venueId, uuid: $uuid, text: $text) {
			__typename
			id
			date
			sender
			text
		}
	}
`;
export type WriteMessageMutationFn = Apollo.MutationFunction<
	WriteMessageMutation,
	WriteMessageMutationVariables
>;

/**
 * __useWriteMessageMutation__
 *
 * To run a mutation, you first call `useWriteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeMessageMutation, { data, loading, error }] = useWriteMessageMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      uuid: // value for 'uuid'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useWriteMessageMutation(
	baseOptions?: Apollo.MutationHookOptions<WriteMessageMutation, WriteMessageMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<WriteMessageMutation, WriteMessageMutationVariables>(
		WriteMessageDocument,
		options,
	);
}
export type WriteMessageMutationHookResult = ReturnType<typeof useWriteMessageMutation>;
export type WriteMessageMutationResult = Apollo.MutationResult<WriteMessageMutation>;
export type WriteMessageMutationOptions = Apollo.BaseMutationOptions<
	WriteMessageMutation,
	WriteMessageMutationVariables
>;
export const WriteVenueFeedbackDocument = gql`
	mutation WriteVenueFeedback($venueId: String!, $input: [[VenueFeedbackInput!]!]!) {
		writeVenueFeedback(venueId: $venueId, input: $input)
	}
`;
export type WriteVenueFeedbackMutationFn = Apollo.MutationFunction<
	WriteVenueFeedbackMutation,
	WriteVenueFeedbackMutationVariables
>;

/**
 * __useWriteVenueFeedbackMutation__
 *
 * To run a mutation, you first call `useWriteVenueFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteVenueFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeVenueFeedbackMutation, { data, loading, error }] = useWriteVenueFeedbackMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteVenueFeedbackMutation(
	baseOptions?: Apollo.MutationHookOptions<
		WriteVenueFeedbackMutation,
		WriteVenueFeedbackMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<WriteVenueFeedbackMutation, WriteVenueFeedbackMutationVariables>(
		WriteVenueFeedbackDocument,
		options,
	);
}
export type WriteVenueFeedbackMutationHookResult = ReturnType<typeof useWriteVenueFeedbackMutation>;
export type WriteVenueFeedbackMutationResult = Apollo.MutationResult<WriteVenueFeedbackMutation>;
export type WriteVenueFeedbackMutationOptions = Apollo.BaseMutationOptions<
	WriteVenueFeedbackMutation,
	WriteVenueFeedbackMutationVariables
>;
export const GetQrCodeByShortCodeDocument = gql`
	query GetQRCodeByShortCode($shortCode: String!) {
		getQRCodeByShortCode(shortCode: $shortCode)
	}
`;

/**
 * __useGetQrCodeByShortCodeQuery__
 *
 * To run a query within a React component, call `useGetQrCodeByShortCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrCodeByShortCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrCodeByShortCodeQuery({
 *   variables: {
 *      shortCode: // value for 'shortCode'
 *   },
 * });
 */
export function useGetQrCodeByShortCodeQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetQrCodeByShortCodeQuery,
		GetQrCodeByShortCodeQueryVariables
	> &
		({ variables: GetQrCodeByShortCodeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetQrCodeByShortCodeQuery, GetQrCodeByShortCodeQueryVariables>(
		GetQrCodeByShortCodeDocument,
		options,
	);
}
export function useGetQrCodeByShortCodeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetQrCodeByShortCodeQuery,
		GetQrCodeByShortCodeQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetQrCodeByShortCodeQuery, GetQrCodeByShortCodeQueryVariables>(
		GetQrCodeByShortCodeDocument,
		options,
	);
}
export function useGetQrCodeByShortCodeSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<
				GetQrCodeByShortCodeQuery,
				GetQrCodeByShortCodeQueryVariables
		  >,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetQrCodeByShortCodeQuery, GetQrCodeByShortCodeQueryVariables>(
		GetQrCodeByShortCodeDocument,
		options,
	);
}
export type GetQrCodeByShortCodeQueryHookResult = ReturnType<typeof useGetQrCodeByShortCodeQuery>;
export type GetQrCodeByShortCodeLazyQueryHookResult = ReturnType<
	typeof useGetQrCodeByShortCodeLazyQuery
>;
export type GetQrCodeByShortCodeSuspenseQueryHookResult = ReturnType<
	typeof useGetQrCodeByShortCodeSuspenseQuery
>;
export type GetQrCodeByShortCodeQueryResult = Apollo.QueryResult<
	GetQrCodeByShortCodeQuery,
	GetQrCodeByShortCodeQueryVariables
>;
export const MeDocument = gql`
	query Me {
		me {
			__typename
			... on Anonymous {
				uuid
			}
			... on ErrorReturn {
				message
			}
		}
	}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
	baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const QrCodeDynamicStepsDocument = gql`
	query QRCodeDynamicSteps($id: String!) {
		qrCodeDynamic(id: $id) {
			steps {
				__typename
				key
				cs {
					__typename
					... on QRCodeDynamicScrollBoxComponent {
						...QRCodeDynamicScrollBoxComponent
					}
					... on QRCodeDynamicTextComponent {
						...QRCodeDynamicTextComponent
					}
					... on QRCodeDynamicFlexBoxComponent {
						...QRCodeDynamicFlexBoxComponent
					}
					... on QRCodeDynamicImageComponent {
						...QRCodeDynamicImageComponent
					}
					... on QRCodeDynamicTextBoxComponent {
						...QRCodeDynamicTextBoxComponent
					}
					... on QRCodeDynamicInfoComponent {
						...QRCodeDynamicInfoComponent
					}
					... on QRCodeDynamicButtonComponent {
						...QRCodeDynamicButtonComponent
					}
					... on QRCodeDynamicLinkComponent {
						...QRCodeDynamicLinkComponent
					}
					... on QRCodeDynamicTextfieldComponent {
						...QRCodeDynamicTextfieldComponent
					}
					... on QRCodeDynamicBinaryChoiceComponent {
						...QRCodeDynamicBinaryChoiceComponent
					}
					... on QRCodeDynamicSelectComponent {
						...QRCodeDynamicSelectComponent
					}
				}
			}
		}
	}
	${QrCodeDynamicScrollBoxComponentFragmentDoc}
	${QrCodeDynamicTextComponentFragmentDoc}
	${QrCodeDynamicFlexBoxComponentFragmentDoc}
	${QrCodeDynamicImageComponentFragmentDoc}
	${QrCodeDynamicTextBoxComponentFragmentDoc}
	${QrCodeDynamicInfoComponentFragmentDoc}
	${QrCodeDynamicButtonComponentFragmentDoc}
	${QrCodeDynamicLinkComponentFragmentDoc}
	${QrCodeDynamicTextfieldComponentFragmentDoc}
	${QrCodeDynamicBinaryChoiceComponentFragmentDoc}
	${QrCodeDynamicSelectComponentFragmentDoc}
`;

/**
 * __useQrCodeDynamicStepsQuery__
 *
 * To run a query within a React component, call `useQrCodeDynamicStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeDynamicStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeDynamicStepsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQrCodeDynamicStepsQuery(
	baseOptions: Apollo.QueryHookOptions<QrCodeDynamicStepsQuery, QrCodeDynamicStepsQueryVariables> &
		({ variables: QrCodeDynamicStepsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QrCodeDynamicStepsQuery, QrCodeDynamicStepsQueryVariables>(
		QrCodeDynamicStepsDocument,
		options,
	);
}
export function useQrCodeDynamicStepsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		QrCodeDynamicStepsQuery,
		QrCodeDynamicStepsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QrCodeDynamicStepsQuery, QrCodeDynamicStepsQueryVariables>(
		QrCodeDynamicStepsDocument,
		options,
	);
}
export function useQrCodeDynamicStepsSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<QrCodeDynamicStepsQuery, QrCodeDynamicStepsQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<QrCodeDynamicStepsQuery, QrCodeDynamicStepsQueryVariables>(
		QrCodeDynamicStepsDocument,
		options,
	);
}
export type QrCodeDynamicStepsQueryHookResult = ReturnType<typeof useQrCodeDynamicStepsQuery>;
export type QrCodeDynamicStepsLazyQueryHookResult = ReturnType<
	typeof useQrCodeDynamicStepsLazyQuery
>;
export type QrCodeDynamicStepsSuspenseQueryHookResult = ReturnType<
	typeof useQrCodeDynamicStepsSuspenseQuery
>;
export type QrCodeDynamicStepsQueryResult = Apollo.QueryResult<
	QrCodeDynamicStepsQuery,
	QrCodeDynamicStepsQueryVariables
>;
export const QrCodeTypeDocument = gql`
	query QRCodeType($id: String!, $language: String!) {
		qrCodeType(id: $id) {
			__typename
			... on QRCodeHelp {
				id
				qrCode {
					...QRCode
				}
			}
			... on QRCodeInternalChat {
				id
				qrCode {
					...QRCode
				}
			}
			... on QRCodeDynamic {
				id
				qrCode {
					...QRCode
				}
			}
			... on ErrorReturn {
				message
			}
		}
	}
	${QrCodeFragmentDoc}
`;

/**
 * __useQrCodeTypeQuery__
 *
 * To run a query within a React component, call `useQrCodeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodeTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useQrCodeTypeQuery(
	baseOptions: Apollo.QueryHookOptions<QrCodeTypeQuery, QrCodeTypeQueryVariables> &
		({ variables: QrCodeTypeQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QrCodeTypeQuery, QrCodeTypeQueryVariables>(QrCodeTypeDocument, options);
}
export function useQrCodeTypeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QrCodeTypeQuery, QrCodeTypeQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QrCodeTypeQuery, QrCodeTypeQueryVariables>(
		QrCodeTypeDocument,
		options,
	);
}
export function useQrCodeTypeSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<QrCodeTypeQuery, QrCodeTypeQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<QrCodeTypeQuery, QrCodeTypeQueryVariables>(
		QrCodeTypeDocument,
		options,
	);
}
export type QrCodeTypeQueryHookResult = ReturnType<typeof useQrCodeTypeQuery>;
export type QrCodeTypeLazyQueryHookResult = ReturnType<typeof useQrCodeTypeLazyQuery>;
export type QrCodeTypeSuspenseQueryHookResult = ReturnType<typeof useQrCodeTypeSuspenseQuery>;
export type QrCodeTypeQueryResult = Apollo.QueryResult<QrCodeTypeQuery, QrCodeTypeQueryVariables>;
export const SubmitDynamicRequestDocument = gql`
	mutation SubmitDynamicRequest(
		$qrCodeId: String!
		$coordinates: CoordinatesInput!
		$language: String!
		$input: [SubmitDynamicRequestInput!]!
	) {
		submitDynamicRequest(
			qrCodeId: $qrCodeId
			coordinates: $coordinates
			language: $language
			input: $input
		)
	}
`;
export type SubmitDynamicRequestMutationFn = Apollo.MutationFunction<
	SubmitDynamicRequestMutation,
	SubmitDynamicRequestMutationVariables
>;

/**
 * __useSubmitDynamicRequestMutation__
 *
 * To run a mutation, you first call `useSubmitDynamicRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDynamicRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDynamicRequestMutation, { data, loading, error }] = useSubmitDynamicRequestMutation({
 *   variables: {
 *      qrCodeId: // value for 'qrCodeId'
 *      coordinates: // value for 'coordinates'
 *      language: // value for 'language'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDynamicRequestMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubmitDynamicRequestMutation,
		SubmitDynamicRequestMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SubmitDynamicRequestMutation, SubmitDynamicRequestMutationVariables>(
		SubmitDynamicRequestDocument,
		options,
	);
}
export type SubmitDynamicRequestMutationHookResult = ReturnType<
	typeof useSubmitDynamicRequestMutation
>;
export type SubmitDynamicRequestMutationResult =
	Apollo.MutationResult<SubmitDynamicRequestMutation>;
export type SubmitDynamicRequestMutationOptions = Apollo.BaseMutationOptions<
	SubmitDynamicRequestMutation,
	SubmitDynamicRequestMutationVariables
>;
export const NearestVenueForTagDocument = gql`
	query NearestVenueForTag($coordinates: LatLngRequiredInput!, $tag: String!) {
		nearestVenueForTag(coordinates: $coordinates, tag: $tag)
	}
`;

/**
 * __useNearestVenueForTagQuery__
 *
 * To run a query within a React component, call `useNearestVenueForTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useNearestVenueForTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNearestVenueForTagQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useNearestVenueForTagQuery(
	baseOptions: Apollo.QueryHookOptions<NearestVenueForTagQuery, NearestVenueForTagQueryVariables> &
		({ variables: NearestVenueForTagQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NearestVenueForTagQuery, NearestVenueForTagQueryVariables>(
		NearestVenueForTagDocument,
		options,
	);
}
export function useNearestVenueForTagLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		NearestVenueForTagQuery,
		NearestVenueForTagQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NearestVenueForTagQuery, NearestVenueForTagQueryVariables>(
		NearestVenueForTagDocument,
		options,
	);
}
export function useNearestVenueForTagSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<NearestVenueForTagQuery, NearestVenueForTagQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<NearestVenueForTagQuery, NearestVenueForTagQueryVariables>(
		NearestVenueForTagDocument,
		options,
	);
}
export type NearestVenueForTagQueryHookResult = ReturnType<typeof useNearestVenueForTagQuery>;
export type NearestVenueForTagLazyQueryHookResult = ReturnType<
	typeof useNearestVenueForTagLazyQuery
>;
export type NearestVenueForTagSuspenseQueryHookResult = ReturnType<
	typeof useNearestVenueForTagSuspenseQuery
>;
export type NearestVenueForTagQueryResult = Apollo.QueryResult<
	NearestVenueForTagQuery,
	NearestVenueForTagQueryVariables
>;
export const GetVenueBySlugDocument = gql`
	query GetVenueBySlug($slug: String!) {
		venueBySlug(slug: $slug) {
			__typename
			id
		}
	}
`;

/**
 * __useGetVenueBySlugQuery__
 *
 * To run a query within a React component, call `useGetVenueBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetVenueBySlugQuery(
	baseOptions: Apollo.QueryHookOptions<GetVenueBySlugQuery, GetVenueBySlugQueryVariables> &
		({ variables: GetVenueBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetVenueBySlugQuery, GetVenueBySlugQueryVariables>(
		GetVenueBySlugDocument,
		options,
	);
}
export function useGetVenueBySlugLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetVenueBySlugQuery, GetVenueBySlugQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetVenueBySlugQuery, GetVenueBySlugQueryVariables>(
		GetVenueBySlugDocument,
		options,
	);
}
export function useGetVenueBySlugSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetVenueBySlugQuery, GetVenueBySlugQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetVenueBySlugQuery, GetVenueBySlugQueryVariables>(
		GetVenueBySlugDocument,
		options,
	);
}
export type GetVenueBySlugQueryHookResult = ReturnType<typeof useGetVenueBySlugQuery>;
export type GetVenueBySlugLazyQueryHookResult = ReturnType<typeof useGetVenueBySlugLazyQuery>;
export type GetVenueBySlugSuspenseQueryHookResult = ReturnType<
	typeof useGetVenueBySlugSuspenseQuery
>;
export type GetVenueBySlugQueryResult = Apollo.QueryResult<
	GetVenueBySlugQuery,
	GetVenueBySlugQueryVariables
>;
export const SubmitVenueHelpRequestDocument = gql`
	mutation SubmitVenueHelpRequest($input: SubmitVenueHelpRequestInput!) {
		submitVenueHelpRequest(input: $input)
	}
`;
export type SubmitVenueHelpRequestMutationFn = Apollo.MutationFunction<
	SubmitVenueHelpRequestMutation,
	SubmitVenueHelpRequestMutationVariables
>;

/**
 * __useSubmitVenueHelpRequestMutation__
 *
 * To run a mutation, you first call `useSubmitVenueHelpRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVenueHelpRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVenueHelpRequestMutation, { data, loading, error }] = useSubmitVenueHelpRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitVenueHelpRequestMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SubmitVenueHelpRequestMutation,
		SubmitVenueHelpRequestMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		SubmitVenueHelpRequestMutation,
		SubmitVenueHelpRequestMutationVariables
	>(SubmitVenueHelpRequestDocument, options);
}
export type SubmitVenueHelpRequestMutationHookResult = ReturnType<
	typeof useSubmitVenueHelpRequestMutation
>;
export type SubmitVenueHelpRequestMutationResult =
	Apollo.MutationResult<SubmitVenueHelpRequestMutation>;
export type SubmitVenueHelpRequestMutationOptions = Apollo.BaseMutationOptions<
	SubmitVenueHelpRequestMutation,
	SubmitVenueHelpRequestMutationVariables
>;
export const GetChatPreviewDocument = gql`
	query GetChatPreview($venueId: String!, $language: String!) {
		venue(venueId: $venueId) {
			__typename
			id
			name
		}
		getVenueTheme(venueId: $venueId) {
			id
			__typename
			texts(language: $language) {
				automaticFirstMessage
				automaticUnavailableMessage
			}
		}
	}
`;

/**
 * __useGetChatPreviewQuery__
 *
 * To run a query within a React component, call `useGetChatPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatPreviewQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetChatPreviewQuery(
	baseOptions: Apollo.QueryHookOptions<GetChatPreviewQuery, GetChatPreviewQueryVariables> &
		({ variables: GetChatPreviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetChatPreviewQuery, GetChatPreviewQueryVariables>(
		GetChatPreviewDocument,
		options,
	);
}
export function useGetChatPreviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetChatPreviewQuery, GetChatPreviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetChatPreviewQuery, GetChatPreviewQueryVariables>(
		GetChatPreviewDocument,
		options,
	);
}
export function useGetChatPreviewSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetChatPreviewQuery, GetChatPreviewQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetChatPreviewQuery, GetChatPreviewQueryVariables>(
		GetChatPreviewDocument,
		options,
	);
}
export type GetChatPreviewQueryHookResult = ReturnType<typeof useGetChatPreviewQuery>;
export type GetChatPreviewLazyQueryHookResult = ReturnType<typeof useGetChatPreviewLazyQuery>;
export type GetChatPreviewSuspenseQueryHookResult = ReturnType<
	typeof useGetChatPreviewSuspenseQuery
>;
export type GetChatPreviewQueryResult = Apollo.QueryResult<
	GetChatPreviewQuery,
	GetChatPreviewQueryVariables
>;
export const GetChatDocument = gql`
	query GetChat($uuid: String!, $venueId: String!) {
		chat(helpRequestId: $uuid, venueId: $venueId) {
			__typename
			id
			messages {
				__typename
				id
				date
				sender
				text
				type
			}
		}
	}
`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useGetChatQuery(
	baseOptions: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables> &
		({ variables: GetChatQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
}
export function useGetChatLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
}
export function useGetChatSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetChatQuery, GetChatQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
}
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatSuspenseQueryHookResult = ReturnType<typeof useGetChatSuspenseQuery>;
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const NewChatMessageDocument = gql`
	subscription NewChatMessage($uuid: String!) {
		newChatMessage(uuid: $uuid) {
			__typename
			id
			date
			sender
			text
			type
		}
	}
`;

/**
 * __useNewChatMessageSubscription__
 *
 * To run a query within a React component, call `useNewChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatMessageSubscription({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useNewChatMessageSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		NewChatMessageSubscription,
		NewChatMessageSubscriptionVariables
	> &
		({ variables: NewChatMessageSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<NewChatMessageSubscription, NewChatMessageSubscriptionVariables>(
		NewChatMessageDocument,
		options,
	);
}
export type NewChatMessageSubscriptionHookResult = ReturnType<typeof useNewChatMessageSubscription>;
export type NewChatMessageSubscriptionResult =
	Apollo.SubscriptionResult<NewChatMessageSubscription>;
export const GetVenueDocument = gql`
	query GetVenue($venueId: String!, $language: String!) {
		venue(venueId: $venueId) {
			__typename
			id
			name
			image
			settings {
				hasChat
				showQuestions
				enablePositionSharing
				requirePosition
				requirePositionForVenue
				requirePositionForQRCodes
				inactivityTimeout
				situations
			}
			isActive
			active {
				feedback {
					text {
						de
						en
					}
					button {
						de
						en
					}
					link
				}
			}
			theme {
				__typename
				id
				texts(language: $language) {
					...VenueThemeText
				}
				images {
					logo
					background
				}
				colors {
					...VenueThemeColors
				}
			}
		}
	}
	${VenueThemeTextFragmentDoc}
	${VenueThemeColorsFragmentDoc}
`;

/**
 * __useGetVenueQuery__
 *
 * To run a query within a React component, call `useGetVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenueQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetVenueQuery(
	baseOptions: Apollo.QueryHookOptions<GetVenueQuery, GetVenueQueryVariables> &
		({ variables: GetVenueQueryVariables; skip?: boolean } | { skip: boolean }),
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetVenueQuery, GetVenueQueryVariables>(GetVenueDocument, options);
}
export function useGetVenueLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetVenueQuery, GetVenueQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetVenueQuery, GetVenueQueryVariables>(GetVenueDocument, options);
}
export function useGetVenueSuspenseQuery(
	baseOptions?:
		| Apollo.SkipToken
		| Apollo.SuspenseQueryHookOptions<GetVenueQuery, GetVenueQueryVariables>,
) {
	const options =
		baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<GetVenueQuery, GetVenueQueryVariables>(GetVenueDocument, options);
}
export type GetVenueQueryHookResult = ReturnType<typeof useGetVenueQuery>;
export type GetVenueLazyQueryHookResult = ReturnType<typeof useGetVenueLazyQuery>;
export type GetVenueSuspenseQueryHookResult = ReturnType<typeof useGetVenueSuspenseQuery>;
export type GetVenueQueryResult = Apollo.QueryResult<GetVenueQuery, GetVenueQueryVariables>;
