import { ApolloLink } from "@apollo/client";

import * as Sentry from "@sentry/react";

export const sentryLink = new ApolloLink((operation, forward) => {
	Sentry.addBreadcrumb({
		category: "query",
		message: operation.operationName,
		level: "info",
	});

	Sentry.setContext("query", {
		operationName: operation.operationName,
		variables: JSON.stringify(operation.variables),
	});

	return forward(operation);
});
