import { makeVar, ReactiveVar } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

class BackendError extends Error {
	code: string;
	statusCode: number;

	constructor(message: string, code: string, statusCode: number) {
		super(message);

		this.code = code;
		this.statusCode = statusCode;
	}
}

export class RateLimitError extends BackendError {
	duration: number | null;

	constructor(message: string, duration: number | null) {
		super(message, "RATE_LIMIT_EXCEEDED", 429);

		this.duration = duration;
	}
}

export class UnknownError extends BackendError {
	constructor(message: string) {
		super(message, "UNKNOWN", 500);
	}
}

export const errorVar: ReactiveVar<BackendError | null> = makeVar<BackendError | null>(null);

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, extensions }) => {
			const errorCode = extensions?.code ?? "UNKNOWN";

			if (errorCode === "RATE_LIMIT_EXCEEDED") {
				const duration = typeof extensions?.duration === "number" ? extensions?.duration : null;

				const error = new RateLimitError(message, duration);

				errorVar(error);
			} else {
				const error = new UnknownError(message);

				errorVar(error);
			}
		});
	}
});
