import { setContext } from "@apollo/client/link/context";

import { getTokenPayload } from "../../auth";

export const jwtService = {
	get: () => sessionStorage.getItem("jwt"),
	set: (token: string) => sessionStorage.setItem("jwt", token),
	clear: () => sessionStorage.removeItem("jwt"),
	isValid: (token: string) => {
		const payload = getTokenPayload(token);
		return payload && payload.exp > Date.now() / 1000;
	},
};

const getToken = () => {
	const accessToken = jwtService.get();

	if (!accessToken) {
		return null;
	}

	const payload = getTokenPayload(accessToken);

	if (!payload) {
		return null;
	}

	if (payload.exp < Date.now() / 1000) {
		jwtService.clear();
		return null;
	} else {
		return accessToken;
	}
};

export const authLink = setContext((_, { headers, ...rest }) => {
	const accessToken = getToken();

	return {
		headers: {
			authorization: accessToken ? `Bearer ${accessToken}` : "",
			...headers,
			...(rest.preview ? { "x-preview": "true" } : {}),
		},
	};
});
