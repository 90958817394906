import unionBy from "lodash.unionby";

export const typePolicies = {
	Chat: {
		fields: {
			messages: {
				merge(existing = [], incoming: any[]) {
					return unionBy([...existing, ...incoming], "__ref");
				},
			},
		},
	},
	Venue: {
		merge: true,
		fields: {
			settings: {
				merge: true,
			},
		},
	},
	Query: {
		fields: {
			me: {
				merge: true,
			},
		},
	},
};
