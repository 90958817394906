import { HttpLink } from "@apollo/client";

import { BACKEND_URL, ENVIRONMENT, FRONTEND_URL } from "../../config";

export const httpLink = new HttpLink({
	uri: BACKEND_URL,
	credentials: ENVIRONMENT === "development" ? undefined : "include",
	headers: {
		"Access-Control-Allow-Origin": FRONTEND_URL,
	},
});
